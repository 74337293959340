import React from 'react';
import './MessageBoardLightboxModal.css';

class MessageBoardLightboxModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items.find(x => x.id === this.props.modalMessageImgUrl).imageUrls,
            currentSlide: 0
        };
    }

    showNextSlide = () => {
        this.setState({
            currentSlide: this.state.currentSlide === this.state.items.length - 1 ? 0 : this.state.currentSlide + 1
        })
    }

    showPreviousSlide = () => {
        this.setState({
            currentSlide: this.state.currentSlide === 0 ? this.state.items.length - 1 : this.state.currentSlide - 1
        })
    }

    render() {
        return (
            <div onClick={e => this.props.closeMethod(e)} className='addMessageBoardLightboxModal'>
                <div className='addMessageBoardLightboxModalWindow'>
                    <i className="material-icons closingIcon">highlight_off</i>
                    {this.state.items.length > 1 &&
                        <div>
                            <span
                                className="material-icons slideChangeIcon slideChangeIcon--left"
                                onClick={this.showPreviousSlide}
                            >
                                navigate_before
                            </span>
                        </div>
                    }
                    <img className='bigImg'
                         src={this.state.items[this.state.currentSlide]}
                         alt='img'
                    />
                    {this.state.items.length > 1 &&
                        <div>
                            <span
                                className="material-icons slideChangeIcon slideChangeIcon--right"
                                onClick={this.showNextSlide}
                            >
                                navigate_next
                            </span>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default MessageBoardLightboxModal;
