import React from 'react';
import List from '../common/List/List';
import Spinner from '../common/Spinner/Spinner';
import MessageDetails from '../MessageDetails/MessageDetails';
import './MessageBoard.css';
import Select from 'react-select';
import {Translation} from "react-i18next";

class MessageBoard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: true,
            items: [],
            dlItems: [],
            dlBcfItems: [],
            goToPage: 1,
            itemsPerPage: 10,
            chosenMessageGroupId: null,
            selectedOptionFloor: null,
            selectedOptionRoom: null,
        };

            this.fetchMessageBoard = this.fetchMessageBoard.bind(this);
            this.handleListItemClick = this.handleListItemClick.bind(this);
            this.handleDownloadClick = this.handleDownloadClick.bind(this);
            this.clearRoomsFilter = this.clearRoomsFilter.bind(this);
            this.emailBcf = this.emailBcf.bind(this);
            this.downloadBcf = this.downloadBcf.bind(this);
    }

    componentDidMount() {
        this.fetchMessageBoard();

    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedProject!==this.props.selectedProject){
            this.fetchMessageBoard();
        }
    }

    fetchMessageBoard() {

        if(this.props.selectedProject) {
            this.setState({
                isLoaded: false
            });

            fetch(`${process.env.REACT_APP_API_URL}/mapperMessagesGroup?buildingId=${this.props.selectedProject}&size=10000&sort=createdAt,desc`, {
                method: 'GET',
                headers: {
                    'Authorization': sessionStorage.getItem('mapperToken')
                }
            })
                .then(res => res.json())
                .then(
                    response => {
                        this.setState({
                                isLoaded: true,
                                items: response._embedded.mapperMessageGroups,
                                error: false
                            }, () => {
                                if (this.state.items.length !== 0) {
                                    this.setState({
                                        chosenMessageGroupId: this.state.items[0].id
                                    })
                                }
                            }
                        );
                    }
                )
                .catch((error) => {
                    this.setState({
                        isLoaded: true,
                        items: [],
                        error
                    });
                })
        }

    }

    handleChangeFloor = selectedOptionFloor => {
        this.setState({
            selectedOptionFloor
        });

        if(selectedOptionFloor) {
            const messageGroupId = this.state.items.find(x => x.floorName === selectedOptionFloor.value).id;
            this.setState({
                chosenMessageGroupId: messageGroupId
            });
        }
    };

    handleChangeRoom = selectedOptionRoom => {
        this.setState({
            selectedOptionRoom
        });

        if(selectedOptionRoom) {
            const messageGroupId = this.state.items.find(x => x.roomName === selectedOptionRoom.value).id;
            this.setState({
                chosenMessageGroupId: messageGroupId
            });
        }
    };

    clearRoomsFilter() {
        this.setState({
            selectedOptionRoom: null
        });
    }

    fetchMessageBoardDownload() {
            fetch(`${process.env.REACT_APP_API_URL}/mapperMessageGroups/${this.state.chosenMessageGroupId}/report`, {
                method: 'GET',
                headers: {
                    'Authorization': sessionStorage.getItem('mapperToken')
                }
            })
                .then(res => res)
                .then(
                    response => {
                        this.setState({
                            dlItems: response.url
                        },() =>  window.open(this.state.dlItems))
                    }
                )
    }

    handleDownloadClick() {
        this.fetchMessageBoardDownload()
    }

    emailBcf() {
        fetch(`${process.env.REACT_APP_API_URL}/mapperMessageGroups/${this.state.chosenMessageGroupId}/bcf`, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
    }

    downloadBcf() {
        fetch(`${process.env.REACT_APP_API_URL}/mapperMessageGroups/${this.state.chosenMessageGroupId}/bcf`, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(res => res)
            .then(
                response => {
                    this.setState({
                        dlBcfItems: response.url
                    },() =>  window.open(this.state.dlBcfItems))
                }
            )
    }

    handleListItemClick(e) {
        if(e.target.tagName.toLowerCase() !== 'a') {
            this.setState({
                chosenMessageGroupId: Number(e.target.parentElement.id)
            });
        }
    }

    render() {

        const { selectedOptionRoom, selectedOptionFloor, error, isLoaded, items, chosenMessageGroupId } = this.state;
        const { selectedProject, projects } = this.props;

        let floorNumbers = items.map(a => a.floorName);

        let filteredFloorNumbers = [];

        floorNumbers.forEach(function(item) {
            if(!filteredFloorNumbers.includes(item)) {
                filteredFloorNumbers.push(item)
            }
        });

        let optionsFloors = filteredFloorNumbers.map(function (floor) {
            return { value: floor, label: floor };
        });

        let filteredFloors = [];

        if(selectedOptionFloor) {
            filteredFloors = items.filter(function (item) {
                return item.floorName === selectedOptionFloor.value;
            });
        }

        let roomNumbers = filteredFloors.map(a => a.roomName);

        let filteredRoomNumbers = [];

        roomNumbers.forEach(function(item) {
            if(!filteredRoomNumbers.includes(item)) {
                filteredRoomNumbers.push(item)
            }
        });

        let optionsRooms = filteredRoomNumbers.map(function (room) {
            return { value: room, label: room };
        });


        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <Spinner/>;
        } else if (!items.length) {
            return <div className='infoBox'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('NoResultsFound')}
                                </span>
                            }
                         </Translation>
                    </div>;
        } else {
            return (
                <div className='page'>
                    <div className='messageBoardHeader'>
                        <div className='messageTitle'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('MessageBoard')}
                                    </span>
                                }
                            </Translation>
                        </div>
                        <div className='messageBoardSelectors'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <p className='filtersTxt'>
                                        {t('Filters')}:
                                    </p>
                                }
                            </Translation>
                            <Translation>
                                {(t, {i18n}) =>
                                    <Select
                                        value={selectedOptionFloor}
                                        onChange={this.handleChangeFloor}
                                        options={optionsFloors}
                                        isClearable
                                        placeholder={t('Floors')}
                                        className='select-style'
                                        onClear={this.clearRoomsFilter}
                                    />
                                }
                            </Translation>
                            <Translation>
                                {(t, {i18n}) =>
                                    <Select
                                        value={selectedOptionRoom}
                                        onChange={this.handleChangeRoom}
                                        options={optionsRooms}
                                        isClearable
                                        isDisabled={selectedOptionFloor === null}
                                        placeholder={t('Rooms')}
                                        className='select-style'
                                    />
                                }
                            </Translation>
                            <div className='boardIcons'>
                                <div
                                    className='messageBoardIcon'
                                    onClick={this.emailBcf}
                                >
                                    <i className="material-icons mIcon">
                                        call_made
                                    </i>
                                    <div className="iconsText">
                                        BCF
                                    </div>
                                </div>

                                <div
                                    className='messageBoardIcon'
                                    onClick={this.downloadBcf}
                                >
                                    <i className="material-icons mIcon">
                                        get_app
                                    </i>
                                    <div className="iconsText">
                                        BCF
                                    </div>
                                </div>

                                <div
                                    className='messageBoardIcon'
                                    onClick={this.handleDownloadClick}
                                >
                                    <i className="material-icons mIcon">
                                        assignment
                                    </i>
                                    <div className="iconsText">
                                        PDF
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pageContent'>
                        <div className='pageContentColumn1-2'>
                            <div className='listTitle'>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Messages')}
                                        </span>
                                    }
                                </Translation>
                            </div>
                            <Translation>
                                {(t, {i18n}) =>
                                    <List
                                        items={items}
                                        filter={true}
                                        filterValue={selectedOptionRoom
                                            ? selectedOptionRoom.value
                                            : selectedOptionFloor
                                                ? selectedOptionFloor.value
                                                : ''
                                        }
                                        filterField={selectedOptionRoom ? 'roomName' : 'floorName'}
                                        columnsTitles={[t('Checker'), t('Building'), t('Floor'), t('Room'), t('Created')]}
                                        columnsSources={['reporterName', 'buildingName', 'floorName', 'roomName', 'createdAt']}
                                        dateColumn='5'
                                        edit={false}
                                        download={false}
                                        delete={false}
                                        currentPage={this.state.goToPage}
                                        itemsPerPage={this.state.itemsPerPage}
                                        searchValue={this.props.searchValue}
                                        handleListItemClick={this.handleListItemClick}
                                        chosenId={chosenMessageGroupId}
                                    />
                                }
                            </Translation>
                        </div>
                        <div className='pageContentColumn2-2'>
                            <div className='listTitle dangerColor'>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('MessageDetails')}
                                        </span>
                                    }
                                </Translation>
                            </div>
                            <MessageDetails
                                projects={projects}
                                chosenMessageGroupId={chosenMessageGroupId}
                                selectedProject={selectedProject}
                                searchValue={this.props.searchValue}
                            />
                        </div>
                    </div>
                </div>
            );

        }
    }
}

export default MessageBoard;
