import React from 'react';
import './CompanyAddModal.css';
import { Translation } from "react-i18next";

function ValidationMessage(props) {
    if (!props.valid && props.message) {
        return (
            <Translation>
                {(t, { i18n }) => <div className='error-msg'>{t(`${props.message}`)}</div>}
            </Translation>
        )
    }
    return null;
}
const statuses = {
    NOT_SEND: 'NOT_SEND',
    WAIT: 'WAIT',
    VERIFIED: 'VERIFIED',
    NOT_VERIFIED: 'NOT_VERIFIED',
}

class CompanyAddModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            nameInput: this.props.name, nameValid: !!this.props.name,
            selectedFile: null,
            arrebarInput: this.props.arrebar,
            ravenInput: this.props.raven,
            companyEmailInput: this.props.companyEmail,
            errorMsg: {},
            email: null,
            verificationStatus: null
        };
        this.handleAddCompaniesClick = this.handleAddCompaniesClick.bind(this);
    }

    componentDidMount = () => {

        if (this.props.id)
            fetch(`${process.env.REACT_APP_API_URL}/companies/${this.props.id}`, {
                method: 'GET',
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                }
            })
                .then(res => res.json())
                .then(res => this.setState({ 
                    verificationStatus: res.sendFromEmail ? (res.sendFromConfirmed ? statuses.VERIFIED : statuses.NOT_VERIFIED) : statuses.NOT_SEND,
                    email: res.sendFromEmail 
                }))
    }
    //Validation functions:
    validateName = () => {
        const { nameInput } = this.state;
        let nameValid = true;
        let errorMsg = { ...this.state.errorMsg };

        if (nameInput === undefined || nameInput === '') {
            nameValid = false;
            errorMsg.nameInput = 'PleaseTypeInTheName'
        } else if (nameInput.length < 3) {
            nameValid = false;
            errorMsg.nameInput = 'NameNeedsToContainAtLeast3Characters'
        } else if (nameInput.length > 30) {
            nameValid = false;
            errorMsg.nameInput = 'NameCannotBeLongerThan30Characters'
        }
        this.setState({ nameValid, errorMsg })
    };

    handleAddCompaniesClick = () => {
        if (this.state.nameValid !== true) {
            this.validateName()
        } else {
            this.props.saveMethod(this.props.id, this.state.nameInput, this.state.arrebarInput, this.state.ravenInput, this.state.selectedFile)
        }
    };

    fileChangeHandler = e => {
        this.setState({
            selectedFile: e.target.files[0]
        });
    };

    sendEmailVerification = (email, id) => {
        this.setState({ verificationStatus: statuses.WAIT })
        fetch(`${process.env.REACT_APP_API_URL}/customCompany/${id}/updateSendFromEmail/${email}`, {
            method: 'PUT',
            headers: {
                'Authorization': sessionStorage.getItem('token')
            }
        })
            .then(() => this.setState({ verificationStatus: statuses.NOT_VERIFIED }))
    }

    checkEmailVerification = (id) => {
        this.setState({ verificationStatus: statuses.WAIT })
        fetch(`${process.env.REACT_APP_API_URL}/customCompany/${id}/updateSendFromEmail`, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('token')
            }
        })
            .then(res => res.json())
            .then(res => {
                if (res.ssendFromConfirmed)
                    this.setState({ verificationStatus: statuses.VERIFIED })
                else
                    this.setState({ verificationStatus: statuses.NOT_VERIFIED })
            })
    }

    render() {
        const { companyEmailInput } = this.state
        return (
            <div className='addCompanyModalBackground'>
                <div className='addCompanyModalWindow'>
                    <div className='addCompanyModalTitle'>
                        <Translation>
                            {(t, { i18n }) =>
                                <span>
                                    {t(this.props.modalTitle)}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className='addCompanyModalForm'>
                        <label className='addCompanyModalInputLabel'>
                            <Translation>
                                {(t, { i18n }) =>
                                    <span>
                                        {t('Name')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.nameInput} />
                        <input className='addCompanyModalInput'
                            defaultValue={this.props.name}
                            onInput={e => this.setState({ nameInput: e.target.value }, this.validateName)}
                            type="text"
                        />
                        <label className='addCompanyModalInputLabel'>
                            <Translation>
                                {(t, { i18n }) =>
                                    <span>
                                        {t('ArrebarEnabled')}
                                    </span>
                                }
                            </Translation>
                        </label>
                        <input className='addCompanyModalInput--checkbox'
                            onChange={e => this.setState({ arrebarInput: !this.state.arrebarInput })}
                            type="checkbox"
                            value={true}
                            defaultChecked={this.props.arrebar}
                        />
                        <label className='addCompanyModalInputLabel'>
                            <Translation>
                                {(t, { i18n }) =>
                                    <span>
                                        {t('RavenEnabled')}
                                    </span>
                                }
                            </Translation>
                        </label>
                        <input className='addCompanyModalInput--checkbox'
                            onChange={e => this.setState({ ravenInput: !this.state.ravenInput })}
                            type="checkbox"
                            value={true}
                            defaultChecked={this.props.raven}
                        />
                        <label className='addCompanyModalInput addCompanyModalUploadFileButton'
                            htmlFor='companyImage' >
                            <span className='addCompanyModalUploadButtonText'>
                                {!this.props.id ?
                                    <Translation>
                                        {(t, { i18n }) =>
                                            <span>
                                                + {t('AddImage')}
                                            </span>
                                        }
                                    </Translation>
                                    :
                                    <Translation>
                                        {(t, { i18n }) =>
                                            <span>
                                                {t('ChangeImage')}
                                            </span>
                                        }
                                    </Translation>
                                }
                            </span>
                            <span className={'addCompanyModalFileAddedInfo'}>
                                {this.state.selectedFile ?
                                    <Translation>
                                        {(t, { i18n }) =>
                                            <span>
                                                {t('Added')}: {this.state.selectedFile.name}
                                            </span>
                                        }
                                    </Translation>
                                    : null
                                }
                            </span>
                            <input className='addCompanyModalFileInput '
                                name='companyImage'
                                id='companyImage'
                                type="file"
                                onChange={this.fileChangeHandler}
                            />
                        </label>
                        <label className='addCompanyModalInputLabel companyEmailLabel'>
                            <Translation>
                                {(t, { i18n }) =>
                                    <span>
                                        {t('CompanyEmail')}
                                    </span>
                                }
                            </Translation>
                        </label>
                        <input className='addCompanyModalInput companyEmailInput'
                            defaultValue={this.props.companyEmail}
                            onInput={e => this.setState({ companyEmailInput: e.target.value, verificationStatus: statuses.NOT_SEND })}
                            type="text"
                            disabled={this.props.id ? false : true}
                            defaultValue={this.state.email ? this.state.email : null}
                        />
                        {this.props.id ?
                            <div className="verificationContainer">
                                {this.state.verificationStatus === statuses.NOT_SEND ?
                                    <>
                                        <button className="sendVerificationBtn" onClick={() => this.sendEmailVerification(companyEmailInput, this.props.id)}>
                                            <Translation>
                                                {(t, { i18n }) =>
                                                    <span>
                                                        {t('Send verification e-mail')}
                                                    </span>
                                                }
                                            </Translation>
                                        </button>
                                    </>
                                    : null}
                                {this.state.verificationStatus === statuses.WAIT ?
                                    <div className="verificationMsg">
                                        <Translation>
                                            {(t, { i18n }) =>
                                                <span>
                                                    {t('Waiting for verification...')}
                                                </span>
                                            }
                                        </Translation>
                                    </div>
                                    : null}
                                {this.state.verificationStatus === statuses.NOT_VERIFIED ?
                                    <>
                                        <button className="sendVerificationBtn" onClick={() => this.checkEmailVerification(this.props.id)}>
                                            <Translation>
                                                {(t, { i18n }) =>
                                                    <span>
                                                        {t('Veryfie')}
                                                    </span>
                                                }
                                            </Translation>
                                        </button>
                                        <div className="verificationMsg">
                                            <i className='material-icons notVerified'>clear</i>
                                            <Translation>
                                                {(t, { i18n }) =>
                                                    <span>
                                                        {t('Not verified')}
                                                    </span>
                                                }
                                            </Translation>
                                        </div>
                                    </>
                                    : null}
                                {this.state.verificationStatus === statuses.VERIFIED ?
                                    <div className="verificationMsg verificationMsg--right">
                                        <i className='material-icons verified'>done</i>
                                        <Translation>
                                            {(t, { i18n }) =>
                                                <span>
                                                    {t('Verified')}
                                                </span>
                                            }
                                        </Translation>
                                    </div>
                                    : null}

                            </div>
                            : null}
                        <div className='addCompanyModalButtons'>
                            <button
                                className='addCompanyModalButton addCompanyModalButton--cancel'
                                onClick={this.props.closeMethod}
                            >
                                <Translation>
                                    {(t, { i18n }) =>
                                        <span>
                                            {t('Cancel').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                            <button
                                className='addCompanyModalButton addCompanyModalButton--save'
                                onClick={this.handleAddCompaniesClick}
                            >
                                <Translation>
                                    {(t, { i18n }) =>
                                        <span>
                                            {t('Save').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyAddModal;
