import React from 'react';
import List from '../common/List/List';
import Spinner from '../common/Spinner/Spinner';
import './MessageDetails.css';
import MessageBoardLightboxModal from "./MessageBoardLightboxModal/MessageBoardLightboxModal";
import {Translation} from "react-i18next";

class MessageDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            chosenMessageGroupId: null,
            goToPage: 1,
            itemsPerPage: 10,
            spreadList: true,
            showMessageBoardLightboxModal: false,
            messageBoardId: null,
        };

        this.fetchMessageDetails = this.fetchMessageDetails.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.handleOpenMessageBoardLightboxModal = this.handleOpenMessageBoardLightboxModal.bind(this);
        this.handleCloseMessageBoardLightboxModal = this.handleCloseMessageBoardLightboxModal.bind(this);
    }

    componentDidMount() {
        this.setState({
                chosenMessageGroupId: this.props.chosenMessageGroupId
            }, () =>
                this.fetchMessageDetails()
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.chosenMessageGroupId!==this.props.chosenMessageGroupId){
            this.fetchMessageDetails();
        }
    }

    fetchMessageDetails() {
        this.setState({
            isLoaded: false
        });

        fetch(`${process.env.REACT_APP_API_URL}/mapperMessages?messageGroupId=${this.props.chosenMessageGroupId}&size=1000`,{
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(res => res.json())
            .then(
                response => {
                    this.setState({
                            isLoaded: true,
                            items: response._embedded.mapperMessages,
                            error: false
                        },() => {
                            if (this.state.items.length !== 0) {
                                this.setState({
                                    chosenMessageGroupId: this.state.items[0].id
                                })
                            }
                        }
                    );
                }
            )
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                    items: [],
                    error
                });
            })
    }

    handleOpenMessageBoardLightboxModal(e) {
        this.setState({
            showMessageBoardLightboxModal: true,
            messageBoardId: Number(e.target.parentElement.parentElement.parentElement.id),
        });

    }

    handleCloseMessageBoardLightboxModal(e) {
        if(e.target.tagName !== 'SPAN') {
            this.setState({
                showMessageBoardLightboxModal: false
            });
        }
    }

    handleListItemClick(e) {
        this.setState({
            chosenMessageGroupId: Number(e.target.parentElement.id)
        });
    }

    render() {
        const { error, isLoaded, items } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <Spinner/>;
        } else if (!items.length) {
            return <div>
                <Translation>
                    {(t, {i18n}) =>
                        <span>
                            {t('NoResultsFound')}
                        </span>
                    }
                </Translation>
            </div>;
        } else {
            return (
                <div>
                    <Translation>
                        {(t, {i18n}) =>
                            <List
                                items={items}
                                filter={false}
                                columnsTitles={[t('Message'), t('Screenshot')]}
                                columnsSources={['content', 'imageUrls']}
                                edit={false}
                                download={false}
                                delete={false}
                                imgColumn='2'
                                currentPage={this.state.goToPage}
                                itemsPerPage={this.state.itemsPerPage}
                                searchValue={this.props.searchValue}
                                handleListItemClick={this.handleListItemClick}
                                handleOpenMessageBoardLightboxModal={this.handleOpenMessageBoardLightboxModal}
                                spreadList={true}
                            />
                        }
                    </Translation>
                    {this.state.showMessageBoardLightboxModal
                        ?
                        <MessageBoardLightboxModal
                            items={items}
                            modalMessageImgUrl={this.state.messageBoardId}
                            closeMethod={this.handleCloseMessageBoardLightboxModal}
                        />
                        :
                        null
                    }
                </div>

            );

        }
    }
}

export default MessageDetails;
