import React from 'react';
import ListItem from './ListItem';
import './List.css';
import {Translation} from "react-i18next";

class List extends React.Component {

    constructor() {
        super();
        this.state = {
            items: [],
            currentPage: 0,
            itemsPerPage: 0,
            searchValue: '',
        };

        this.changePage = this.changePage.bind(this);
        this.changePageToPrevious = this.changePageToPrevious.bind(this);
        this.changePageToNext = this.changePageToNext.bind(this);
        this.searchItems = this.searchItems.bind(this);
    };

    componentDidMount() {

        this.searchItems();

        this.setState({
            currentPage: this.props.currentPage,
            itemsPerPage: this.props.itemsPerPage
        });
    }

    searchItems() {

        let filteredItems = [];
        let searchValue = this.state.searchValue;

        (searchValue !== '')
            ?
            filteredItems = this.props.items.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()))
            :
            filteredItems = this.props.items;

        this.setState({
            items: filteredItems,
            currentPage: 1
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.searchValue !== state.searchValue) {
            return {
                searchValue: props.searchValue,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.searchValue !== prevProps.searchValue) {
            this.searchItems();
        }
    }

    changePage(event) {
        this.setState({
            currentPage: Number(event.target.id)
        });
    }

    changePageToPrevious() {
        if(this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            });
        }
    }

    changePageToNext() {
        if(this.state.currentPage < Math.ceil(this.state.items.length / this.state.itemsPerPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            });
        }
    }

    render() {

        const { items, currentPage, itemsPerPage } = this.state;
        const { filter, filterValue, filterField } = this.props;

        let filteredItems =[];

        filteredItems = filter && filterValue ? items.filter(item => item[filterField] === filterValue) : items;


        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

        let pageNumbers = [];
        for (let i = 1; i <= Math.ceil(filteredItems.length / itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        let firstPageToShow = 0;
        let lastPageToShow = filteredItems.length;

        if (pageNumbers.length > 5) {

            if (this.state.currentPage >= 3) {
                firstPageToShow = this.state.currentPage - 3;
            }

            lastPageToShow = this.state.currentPage + 2;
        }

        const pageNumbersToShow = pageNumbers.slice(firstPageToShow, lastPageToShow);

        const renderPageNumbers = pageNumbersToShow.map(number => {
            return (

                (number === this.state.currentPage)
                    ?
                        <li
                            className='pageNumber activePage'
                            key={number}
                            id={number}
                            onClick={this.changePage}
                        >
                            {number}
                        </li>
                    :
                        <li
                            className='pageNumber'
                            key={number}
                            id={number}
                            onClick={this.changePage}
                        >
                            {number}
                        </li>
            );
        });

        const columnsNumber = (this.props.edit || this.props.download || this.props.delete)
            ? this.props.columnsTitles.length + 1
            : this.props.columnsTitles.length;

        let columnWidth = (100 / columnsNumber) + '%';

        return (
            <div className='list'>
                <div className='listContainer'>
                    <table className='listTable'>
                        <thead>
                            <tr className='tableHeader'>

                                {this.props.columnsTitles.map((col, index) => (
                                    (this.props.spreadList && index === 0) ?
                                        <th
                                            key={index}
                                            className={'tableHeaderField tableHeaderField--column' + (index + 1)}
                                            style={{width: '80%'}}
                                        >
                                            {this.props.columnsTitles[index]}
                                        </th>
                                        :
                                        (this.props.spreadList && index === 1) ?
                                            <th
                                                key={index}
                                                className={'tableHeaderField tableHeaderField--column' + (index + 1)}
                                                style={{width: '20%', paddingRight: '20px'}}
                                            >
                                                {this.props.columnsTitles[index]}
                                            </th>
                                            :
                                            <th
                                                key={index}
                                                className={'tableHeaderField tableHeaderField--column' + (index + 1)}
                                                style={{width: columnWidth}}
                                            >
                                                {this.props.columnsTitles[index]}
                                            </th>
                                ))}

                                {(this.props.edit || this.props.download || this.props.delete)
                                    ? <th className='tableHeaderField tableHeaderField--actions'></th>
                                    : null
                                }
                            </tr>
                        </thead>
                        <tbody className={'tableContent'}>

                            {
                                currentItems.map((item, index) => (

                                    <ListItem
                                        key={index}
                                        item={item}
                                        id={item.id}
                                        columnsSources={this.props.columnsSources}
                                        dateColumn={this.props.dateColumn}
                                        linkColumn={this.props.linkColumn}
                                        imgColumn={this.props.imgColumn}
                                        linkText={this.props.linkText}
                                        linkUnavailableText={this.props.linkUnavailableText}
                                        referenceColumn={this.props.referenceColumn}
                                        referenceItems={this.props.referenceItems}
                                        layerColorColumn={this.props.layerColorColumn}
                                        booleanColumns={this.props.booleanColumns}
                                        roleColumns={this.props.roleColumns}
                                        statusColumn={this.props.statusColumn}
                                        statusTotal={this.props.statusTotal}
                                        edit={this.props.edit}
                                        editMethod={this.props.editMethod}
                                        download={this.props.download}
                                        downloadUrl={this.props.downloadUrl}
                                        delete={this.props.delete}
                                        deleteMethod={this.props.deleteMethod}
                                        handleListItemClick={this.props.handleListItemClick}
                                        handleOpenMessageBoardLightboxModal={this.props.handleOpenMessageBoardLightboxModal}
                                        fileChangedHandler={this.props.fileChangedHandler}
                                        chosenId={this.props.chosenId}
                                    />
                                ))
                            }


                            {(pageNumbers.length >= 2)
                                ?
                                <tr className='paginationRow'>
                                    <td className='paginationField' colSpan={columnsNumber}>
                                        <ul className='pageNumbers'>
                                            <li className='previousPageButton' onClick={this.changePageToPrevious}>
                                                <Translation>
                                                    {(t, {i18n}) =>
                                                        <span>
                                                            {t('Previous')}
                                                        </span>
                                                    }
                                                </Translation>
                                            </li>

                                            {(this.state.currentPage >= 4 && pageNumbers.length > 5)
                                                ?
                                                <li
                                                    className='pageNumber'
                                                    key={1}
                                                    id={1}
                                                    onClick={this.changePage}
                                                >
                                                    1
                                                </li>
                                                :
                                                null
                                            }
                                            {(this.state.currentPage >= 5 && pageNumbers.length > 5)
                                                ?
                                                <li  style={{fontWeight: '200'}}>
                                                    &nbsp;...&nbsp;
                                                </li>
                                                :
                                                null
                                            }
                                            {renderPageNumbers}
                                            {(this.state.currentPage <= pageNumbers.length - 4 && pageNumbers.length > 5)
                                                ?
                                                    <li style={{fontWeight: '200'}}>
                                                        &nbsp;...&nbsp;
                                                    </li>
                                                :
                                                    null
                                            }
                                            {(this.state.currentPage <= pageNumbers.length - 3 && pageNumbers.length > 5)
                                                ?
                                                    <li
                                                        className='pageNumber'
                                                        key={pageNumbers.length}
                                                        id={pageNumbers.length}
                                                        onClick={this.changePage}
                                                    >
                                                        {pageNumbers.length}
                                                    </li>
                                                :
                                                null
                                            }
                                            <li className='nextPageButton' onClick={this.changePageToNext}>
                                                <Translation>
                                                    {(t, {i18n}) =>
                                                        <span>
                                                            {t('Next')}
                                                        </span>
                                                    }
                                                </Translation>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                :
                                null
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default List;
