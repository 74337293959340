import React from 'react';
import {getTokenExp} from "../../helpers/helpers";

import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import MainWindow from "../MainWindow/MainWindow";

class MainPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sideBarLabelsShow: false,
            selectedProject: Number(sessionStorage.getItem('currentProjectId'))
                ? Number(sessionStorage.getItem('currentProjectId'))
                : undefined
            ,
            searchValue: '',
            projects: []
        };

        this.showSideBarLabels = this.showSideBarLabels.bind(this);
        this.changeProject = this.changeProject.bind(this);
        this.fetchProjects = this.fetchProjects.bind(this);
        this.updateProjectSelector = this.updateProjectSelector.bind(this);
    }

    componentDidMount() {
        if(sessionStorage.getItem('mapperToken') && (getTokenExp() > Date.now() / 1000)) {
            this.fetchProjects();
        }
    }

    fetchProjects() {

        this.setState({
            isLoaded: false
        });

        fetch(`${process.env.REACT_APP_API_URL}/mapperBuildings/`, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(res => res.json())
            .then(
                response => {
                    this.setState({
                        isLoaded: true,
                        projects: response._embedded.buildings,
                        error: false
                    });
                }
            )
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                    projects: [],
                    error
                });
            })
    }

    showSideBarLabels() {
        this.setState({
            sideBarLabelsShow: !this.state.sideBarLabelsShow
        })
    }

    changeProject(id) {
        this.setState({
            selectedProject: id
        },() => Number(sessionStorage.setItem('currentProjectId', id)))
    }

    updateProjectSelector(id) {
        this.fetchProjects();
        this.changeProject(id);
    }

    render() {
        return (
            <div className="app">
                <Header
                    showSideBarLabels={this.showSideBarLabels}
                    projects={this.state.projects}
                    changeProject={this.changeProject}
                    selectedProject={this.state.selectedProject}
                />
                <div className='container'>
                    <SideBar
                        sideBarLabelsShow={this.state.sideBarLabelsShow}
                        selectedProject={this.state.selectedProject}
                    />
                    <MainWindow
                        sideBarLabelsShow={this.state.sideBarLabelsShow}
                        searchValue={this.state.searchValue}
                        selectedProject={this.state.selectedProject}
                        updateProjectSelector={this.updateProjectSelector}
                        projects={this.state.projects}
                    />
                </div>
            </div>
        );
    }
}

export default MainPage;
