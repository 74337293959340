import React, {Component} from 'react';
import './Companies.css';
import {getCompanyId, isSuperAdmin} from '../../helpers/helpers';

import List from '../common/List/List';
import CompanyInfo from '../CompanyInfo/CompanyInfo';
import CompanyAddModal from "../Companies/CompanyAddModal/CompanyAddModal";
import DeleteModal from '../common/DeleteModal/DeleteModal';
import Spinner from '../common/Spinner/Spinner';
import {Translation} from "react-i18next";

class Companies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: true,
            items: [],
            goToPage: 1,
            itemsPerPage: 10,
            chosenCompanyId: null,
            showAddModal: false,
            editedItemName: '',
            showDeleteModal: false
        };

        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.createCompany = this.createCompany.bind(this);
        this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.deleteCompany = this.deleteCompany.bind(this);
    }

    componentDidMount() {
        this.fetchCompanies();
    }

    fetchCompanies(id) {

        this.setState({
            isLoaded: false
        });

        const apiUrl = isSuperAdmin() ? `${process.env.REACT_APP_API_URL}/companies?size=1000` : `${process.env.REACT_APP_API_URL}/companies?size=1000&id=${getCompanyId()}`;

        fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(res => res.json())
            .then(
                response => {

                    if (response._embedded.companies.length !== 0) {

                        const index = response._embedded.companies.map(function(e) {return e.id; }).indexOf(id);
                        const page = index === -1 ? 1 : Math.floor(index / this.state.itemsPerPage) + 1;

                        !(index === -1)  ?
                            this.setState({
                                chosenCompanyId: id,
                                chosenCompany: response._embedded.companies.find(x => x.id === id),
                                chosenCompanyName: response._embedded.companies.find(x => x.id === id).name,
                                goToPage: page,
                                isLoaded: true,
                                items: response._embedded.companies,
                                error: false
                            })
                            :
                            this.setState({
                                chosenCompanyId: response._embedded.companies[0].id,
                                chosenCompany: response._embedded.companies[0],
                                chosenCompanyName: response._embedded.companies[0].name,
                                goToPage: page,
                                isLoaded: true,
                                items: response._embedded.companies,
                                error: false
                            })
                    }
                }
            )
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                    items: [],
                    error
                });
            })
    }

    handleOpenAddModal() {
        this.setState({
            showAddModal: true
        });
    }

    handleCloseAddModal() {
        this.setState({
            showAddModal: false
        });
    }

    async createCompany(id, name, arrebar, raven, image) {

        this.setState({
            isLoaded: false
        });

        const response =  await fetch(`${process.env.REACT_APP_API_URL}/companies/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('mapperToken')
            },
            body: JSON.stringify({
                name: name,
                arrebarEnabled: arrebar,
                ravenEnabled: raven
            })
        });
        const json = await response.json();

        if(image) {
            this.addPhoto(image, json.id)
        } else {
            this.fetchCompanies(json.id)
            this.handleCloseAddModal()
        }
    };

    addPhoto = (image, jsonId) => {
        if(image) {
            const formData = new FormData();
            formData.append('file', image, image.name);

            fetch(`${process.env.REACT_APP_API_URL}/customCompany/${jsonId}/logo_upload`, {
                method: 'PUT',
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                },
                body: formData
            })
                .then(() => this.fetchCompanies(jsonId))
                .then(this.handleCloseAddModal)
        }
    }

    handleOpenDeleteModal() {

        this.setState({
            showDeleteModal: true
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    deleteCompany() {

        const deleteItemId = this.state.chosenCompanyId;

        fetch(`${process.env.REACT_APP_API_URL}/companies/${deleteItemId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(this.fetchCompanies)
            .then(this.handleCloseDeleteModal)
    }

    handleListItemClick(e) {
        if(e.target.tagName.toLowerCase() !== 'i') {
            this.setState({
                chosenCompanyId: Number(e.target.parentElement.id),
                chosenCompanyName: this.state.items.find(x => x.id === this.state.chosenCompanyId).name
            });
        } else {
            this.setState({
                chosenCompanyId: Number(e.target.parentElement.parentElement.id),
                chosenCompanyName: this.state.items.find(x => x.id === this.state.chosenCompanyId).name
            });
        }
    }

    render() {

        const { error, isLoaded, items } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <Spinner/>;
        } else {
            return (
                <div className='page'>
                    <div className='pageHeader'>
                        <div>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Companies')}
                                    </span>
                                }
                            </Translation>
                        </div>
                        {isSuperAdmin() ?
                            <div className='buttonsWrapper'>
                                <i className='material-icons createIcon'
                                   onClick={this.handleOpenAddModal}
                                >
                                    add
                                </i>
                            </div>
                            : null
                        }
                    </div>
                    <div className='pageContent'>
                        <div className='pageContentColumn1-2'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <List
                                        items={items}
                                        filter={false}
                                        columnsTitles={[t('Name'), t('ArrebarEnabled'), t('RavenEnabled')]}
                                        columnsSources={['name', 'arrebarEnabled', 'ravenEnabled']}
                                        booleanColumns={[2, 3]}
                                        edit={false}
                                        download={false}
                                        delete={false}
                                        currentPage={this.state.goToPage}
                                        itemsPerPage={this.state.itemsPerPage}
                                        searchValue={this.props.searchValue}
                                        handleListItemClick={this.handleListItemClick}
                                        chosenId={this.state.chosenCompanyId}
                                    />
                                }
                            </Translation>
                        </div>
                        <div className='pageContentColumn2-2'>
                            {this.state.chosenCompanyId ?
                                <CompanyInfo
                                    item={items.find(x => x.id === this.state.chosenCompanyId)}
                                    handleOpenDeleteModal={this.handleOpenDeleteModal}
                                    fetchCompanies={this.fetchCompanies}
                                    chosenCompanyId={this.state.chosenCompanyId}
                                />
                                : null}
                        </div>
                    </div>

                    {this.state.showAddModal
                        ?
                        <CompanyAddModal
                            modalTitle='Add new company'
                            closeMethod={this.handleCloseAddModal}
                            saveMethod={this.createCompany}
                        />
                        :
                        null
                    }

                    {this.state.showDeleteModal
                        ?
                        <DeleteModal
                            itemName={items.find(x => x.id === this.state.chosenCompanyId).name}
                            closeMethod={this.handleCloseDeleteModal}
                            deleteMethod={this.deleteCompany}
                        />
                        :
                        null
                    }
                </div>
            );
        }
    }
}

export default Companies;
