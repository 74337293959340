import React from 'react';
import { Route } from 'react-router-dom';
import './MainWindow.css';
import {isAdmin, isDesigner, isSuperAdmin} from '../../helpers/helpers';

import BuildingsDetails from '../../components/BuildingsDetails/BuildingsDetails';
import Layers from '../../components/Layers/Layers';
import Floors from '../../components/Floors/Floors';
import MessageBoard from '../../components/MessageBoard/MessageBoard';
import DefaultMessages from '../../components/DefaultMessages/DefaultMessages';
import Companies from '../../components/Companies/Companies';
import Users from '../../components/Users/Users';

class MainWindow extends React.Component {
    render() {
        return (
            <div
                className={this.props.sideBarLabelsShow
                    ? 'mainWindow mainWindow--sideBarActive'
                    : 'mainWindow'}
            >
                <Route
                    exact path='/buildingsDetails'
                    render={(props) =>
                        <BuildingsDetails
                            {...props}
                            searchValue={this.props.searchValue}
                            selectedProject={this.props.selectedProject}
                            updateProjectSelector={this.props.updateProjectSelector}
                        />}
                />
                {isDesigner() || isAdmin() || isSuperAdmin() ?
                    <Route
                        exact path='/layers'
                        render={(props) =>
                            <Layers
                                {...props}
                                searchValue={this.props.searchValue}
                            />}
                    />
                    : null
                }
                <Route
                    exact path='/floors'
                    render={(props) =>
                        <Floors
                            {...props}
                            searchValue={this.props.searchValue}
                            selectedProject={this.props.selectedProject}
                            projects={this.props.projects}
                        />}
                />
                <Route
                    exact path='/messageBoard'
                    render={(props) =>
                        <MessageBoard
                            {...props}
                            searchValue={this.props.searchValue}
                            selectedProject={this.props.selectedProject}
                            updateProjectSelector={this.props.updateProjectSelector}
                        />}
                />
                <Route
                    exact path='/defaultMessages'
                    render={(props) =>
                        <DefaultMessages
                            {...props}
                            searchValue={this.props.searchValue}
                        />}
                />
                {isAdmin() || isSuperAdmin() ?
                    <Route
                        exact path='/companies'
                        render={(props) =>
                            <Companies
                                {...props}
                                searchValue={this.props.searchValue}
                            />}
                    />
                    : null
                }
                {isAdmin() || isSuperAdmin() ?
                    <Route
                        exact path='/users'
                        render={(props) =>
                            <Users
                                {...props}
                                searchValue={this.props.searchValue}
                            />}
                    />
                    : null
                }
            </div>
        );
    }
}

export default MainWindow;
