import React from 'react';
import './DeleteModal.css';
import {Translation} from "react-i18next";

class DeleteModal extends React.Component {

    render() {
        return (
            <div className='deleteModalBackground'>
                <div className='deleteModalWindow'>
                    <div className='deleteModalTitle'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('DeleteConfirmation')}
                                </span>
                            }
                        </Translation>
                        <br/>
                        {this.props.itemName}?
                    </div>
                    <div className='deleteModalButtons'>
                        <button
                            className='deleteModalButton deleteModalButton--cancel'
                            onClick={this.props.closeMethod}
                        >
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Cancel').toUpperCase()}
                                    </span>
                                }
                            </Translation>
                        </button>
                        <button
                            className='deleteModalButton deleteModalButton--delete'
                            onClick={this.props.deleteMethod}
                        >
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Delete').toUpperCase()}
                                    </span>
                                }
                            </Translation>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteModal;
