import React from 'react';
import './BuildingAddModal.css';
import { Translation } from 'react-i18next';

function ValidationMessage(props) {
    if (!props.valid && props.message) {
        return(
            <Translation>
                {(t, {i18n}) => <div className='error-msg'>{t(`${props.message}`)}</div>}
            </Translation>
        )
    }
    return null;
}

class BuildingAddModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            supervisors: [],
            nameInput: this.props.name, nameValid: !!this.props.name,
            locationInput: this.props.location,
            descriptionInput: this.props.description, descriptionValid: true,
            supervisorInput: this.props.supervisorId, supervisorValid: !!this.props.supervisorId,
            selectedSupervisor: null,
            showSupervisorList: false,
            imageUrl: this.props.imageUrl,
            selectedFile: null,
            errorMsg: {}
        };

        this.fetchSupervisors=this.fetchSupervisors.bind(this);
        this.showSupervisorList=this.showSupervisorList.bind(this);
        this.hideSupervisorList=this.hideSupervisorList.bind(this);
        this.handleSupervisorSelect=this.handleSupervisorSelect.bind(this);
        this.handleAddEditBuildingClick=this.handleAddEditBuildingClick.bind(this);
    }

    componentDidMount() {
        this.fetchSupervisors()
    }

    fetchSupervisors() {

        fetch(`${process.env.REACT_APP_API_URL}/users?role=SUPERVISOR`, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(res => res.json())
            .then(
                response => {
                    this.setState({
                        supervisors: response._embedded.users,
                        selectedSupervisor: response._embedded.users.find(x => x.id === this.props.supervisorId),
                        error: false
                    });
                }
            )
            .catch((error) => {
                this.setState({
                    items: [],
                    error
                });
            })
    }

    showSupervisorList() {
        this.setState({
            showSupervisorList: true
        })
    }

    hideSupervisorList() {
        this.setState({
            showSupervisorList: false
        })
    }

    handleSupervisorSelect(e) {
        this.setState({
            supervisorInput: e.target.id,
            selectedSupervisor: this.state.supervisors.find(x => x.id === Number(e.target.id))
        }, () => this.validateSupervisor());

        const options = document.querySelectorAll('.supervisorList li');

        options.forEach((option) => {
            option.classList.remove('selected');
        });

        e.target.classList.add('selected');
    }

    fileChangeHandler = e => {
        this.setState({
            selectedFile: e.target.files[0]
        });
    };

    //Validation Functions:
    validateName = () => {
        const {nameInput} = this.state;
        let nameValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(nameInput === undefined || nameInput === '') {
            nameValid = false;
            errorMsg.nameInput = 'PleaseTypeInTheName'
        } else if(nameInput.length < 3) {
            nameValid = false;
            errorMsg.nameInput = 'NameNeedsToContainAtLeast3Characters'
        } else if(nameInput.length > 30) {
            nameValid = false;
            errorMsg.nameInput = 'NameCannotBeLongerThan30Characters'
        }
        this.setState({nameValid, errorMsg})
    };

    validateDescription = () => {
        const {descriptionInput} = this.state;
        let descriptionValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(descriptionInput.length > 250) {
            descriptionValid = false;
            errorMsg.descriptionInput = 'DescriptionCannotBeLongerThan250Characters'
        }
        this.setState({descriptionValid, errorMsg})
    };

    validateSupervisor = () => {
        const {selectedSupervisor} = this.state;
        let supervisorValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(selectedSupervisor === undefined || selectedSupervisor === null) {
            supervisorValid = false;
            errorMsg.selectedSupervisor = 'PleaseSelectASupervisor'
        }
        this.setState({supervisorValid, errorMsg})
    };

    handleAddEditBuildingClick = () => {
        if(this.state.nameValid !== true) {
            this.validateName()
        } else if(this.state.descriptionValid !== true) {
            this.validateDescription()
        } else if(this.state.supervisorValid !== true) {
            this.validateSupervisor()
        } else {
            this.props.saveMethod(this.props.id, this.state.nameInput, this.state.descriptionInput, this.state.locationInput, this.state.supervisorInput, this.state.selectedFile, this.state.imageUrl)
        }
    };

    render() {
        return (
            <div className='addBuildingModalBackground'>
                <div className='addBuildingModalWindow'>
                    <div className='addBuildingModalTitle'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t(this.props.modalTitle)}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className='addBuildingModalForm'>
                        <label className='addBuildingModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Name')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.nameInput} />
                        <input
                            className='addBuildingModalInput addBuildingModalInput--buildingName'
                            defaultValue={this.props.name}
                            onInput={e => this.setState({nameInput: e.target.value}, this.validateName)}
                            type="text"
                        />

                        <label className='addBuildingModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Location')}
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.locationValid} message={this.state.errorMsg.locationInput} />
                        <input
                            className='addBuildingModalInput addBuildingModalInput--buildingLocation'
                            defaultValue={this.props.location}
                            onInput={e => this.setState({locationInput: e.target.value}, this.validateLocation)}
                            type="text"
                        />
                        <label className='addBuildingModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Description')}
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.descriptionValid} message={this.state.errorMsg.descriptionInput} />
                        <textarea
                            className='addBuildingModalInput addBuildingModalInput--buildingDescription'
                            defaultValue={this.props.description}
                            onInput={e => this.setState({descriptionInput: e.target.value}, this.validateDescription)}
                            type="text"
                        />
                        <label className='addBuildingModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Supervisor')}
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.supervisorValid} message={this.state.errorMsg.selectedSupervisor} />
                        <div
                            className='addLayerModalInput addBuildingSupervisorSelect'
                            onClick={!this.state.showSupervisorList ? this.showSupervisorList : this.hideSupervisorList}
                        >
                            <div className='addBuildingSupervisorDisplay'>
                                <div className='addBuildingSupervisor'>
                                    {this.state.selectedSupervisor
                                        ? this.state.selectedSupervisor.firstName
                                            + ' ' + this.state.selectedSupervisor.lastName
                                            + ' - ' + this.state.selectedSupervisor.email
                                        : ''
                                    }
                                </div>
                            </div>
                            {!this.state.showSupervisorList ?
                                <i className='material-icons dropdownIcon'>
                                    keyboard_arrow_down
                                </i>
                                :
                                <i className='material-icons dropdownIcon'>
                                    keyboard_arrow_up
                                </i>
                            }
                        </div>
                        <div onMouseLeave={this.hideSupervisorList}>
                            <ul className={this.state.showSupervisorList ? 'supervisorList supervisorList--active' : 'supervisorList'}>
                                {this.state.supervisors.map((supervisor, index) => {
                                    return (
                                        <li
                                            id={supervisor.id}
                                            key={index}
                                            onClick={this.handleSupervisorSelect}
                                        >
                                            {supervisor.firstName} {supervisor.lastName} - {supervisor.email}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        <label className='addBuildingModalInput addBuildingModalUploadFileButton'
                               htmlFor='buildingPhoto' >
                            <span className='addBuildingModalUploadButtonText'>
                                {!this.props.id ?
                                    <Translation>
                                        {(t, {i18n}) =>
                                            <span>
                                                + {t('AddPhoto')}
                                            </span>
                                        }
                                    </Translation>
                                    :
                                    <Translation>
                                        {(t, {i18n}) =>
                                            <span>
                                                {t('ChangePhoto')}
                                            </span>
                                        }
                                    </Translation>
                                }
                            </span>
                            <span className={'addBuildingModalFileAddedInfo'}>
                                {this.state.selectedFile ?
                                    <Translation>
                                        {(t, {i18n}) =>
                                            <span>
                                                {t('Added')}: {this.state.selectedFile.name}
                                            </span>
                                        }
                                    </Translation>
                                    : null
                                }
                            </span>
                            <input className='addBuildingModalFileInput'
                                   name='buildingPhoto'
                                   id='buildingPhoto'
                                   type="file"
                                   onChange={this.fileChangeHandler}
                            />
                        </label>

                        <div className='addBuildingModalButtons'>
                            <button
                                className='addBuildingModalButton addBuildingModalButton--cancel'
                                onClick={this.props.closeMethod}
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Cancel').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                            <button
                                className='addBuildingModalButton addBuildingModalButton--save'
                                onClick={this.handleAddEditBuildingClick}
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Save').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BuildingAddModal;
