import React, {Component} from 'react';
import i18n from '../../../i18n';
import {Translation} from "react-i18next";
import './LanguageSelect.css';
import langIcon1 from "../../../assets/icons/lang/united-kingdom-flag-round-icon-32.png";
import langIcon2 from "../../../assets/icons/lang/germany-flag-round-icon-32.png";
import langIcon3 from "../../../assets/icons/lang/france-flag-round-icon-32.png";
import langIcon4 from "../../../assets/icons/lang/netherlands-flag-round-icon-32.png";

class LanguageSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedLanguage: 1,
            languageIcon: langIcon1,
            showDialog: false
        };

        this.langChangeEN = this.langChangeEN.bind(this);
        this.langChangeDE = this.langChangeDE.bind(this);
        this.langChangeFR = this.langChangeFR.bind(this);
        this.langChangeNL = this.langChangeNL.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this);
    }

    langChangeEN() {
        this.setState({
            selectedLanguage: 1,
            showDialog: false,
            languageIcon: langIcon1
        });
        i18n.changeLanguage('en');
    }

    langChangeDE() {
        this.setState({
            selectedLanguage: 2,
            showDialog: false,
            languageIcon: langIcon2
        });
        i18n.changeLanguage('de');
    }

    langChangeFR() {
        this.setState({
            selectedLanguage: 3,
            showDialog: false,
            languageIcon: langIcon3
        });
        i18n.changeLanguage('fr');
    }

    langChangeNL() {
        this.setState({
            selectedLanguage: 4,
            showDialog: false,
            languageIcon: langIcon4
        });
        i18n.changeLanguage('nl');
    }

    handleClick = () => {
        this.setState({ showDialog: !this.state.showDialog });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    render() {

        const {showDialog, selectedLanguage, languageIcon} = this.state;

        return (
            <div className='changeLanguageButtonHolder'>

                <div className='changeLanguageButton' onClick={this.handleClick}>
                    <div className='changeLanguageButtonLabel'>
                        <img className='countryIconImg'
                             src={languageIcon}
                             alt='language country'
                        />
                    </div>
                </div>

                {/*{showDialog ?*/}
                    <ul
                        onClick={this.handleChange}
                        onMouseLeave={this.handleCloseClick}
                        className={showDialog ? 'countryList countryList--show' : 'countryList'}
                    >
                        <li
                            onClick={this.langChangeEN}
                            className='countryListItem'
                        >
                            <img
                                className={selectedLanguage === 1 ? 'countryIconImg active__icon' : 'countryIconImg'}
                                src={langIcon1}
                                alt='language country'
                            />
                            <Translation>
                                {(t, {i18n}) => <p className='countryName'>{t('English')}</p>}
                            </Translation>
                        </li>
                        <li
                            onClick={this.langChangeDE}
                            className='countryListItem'
                        >
                            <img
                                className={selectedLanguage === 2 ? 'countryIconImg active__icon' : 'countryIconImg'}
                                src={langIcon2}
                                alt='language country'
                            />
                            <Translation>
                                {(t, {i18n}) => <p className='countryName'>{t('German')}</p>}
                            </Translation>
                        </li>
                        <li
                            onClick={this.langChangeFR}
                            className='countryListItem'
                        >
                            <img
                                className={selectedLanguage === 3 ? 'countryIconImg active__icon' : 'countryIconImg'}
                                src={langIcon3}
                                alt='language country'
                            />
                            <Translation>
                                {(t, {i18n}) => <p className='countryName'>{t('French')}</p>}
                            </Translation>
                        </li>
                        <li
                            onClick={this.langChangeNL}
                            className='countryListItem'
                        >
                            <img
                                className={selectedLanguage === 4 ? 'countryIconImg active__icon' : 'countryIconImg'}
                                src={langIcon4}
                                alt='language country'
                            />
                            <Translation>
                                {(t, {i18n}) => <p className='countryName'>{t('Dutch')}</p>}
                            </Translation>
                        </li>
                    </ul>
                {/*    :*/}
                {/*    null*/}
                {/*}*/}

            </div>
        );
    }
}

export default LanguageSelect;
