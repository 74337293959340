import React from 'react';
import './IfcEditModal.css';
import {Translation} from "react-i18next";

function ValidationMessage(props) {
    if (!props.valid && props.message) {
        return(
            <Translation>
                {(t, {i18n}) => <div className='error-msg'>{t(`${props.message}`)}</div>}
            </Translation>
        )
    }
    return null;
}

class IfcEditModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            nameInput: this.props.ifc.name, nameValid: this.props.ifc.name ? true : false,
            prefixInput: this.props.ifc.prefix, prefixValid: this.props.ifc.prefix ? true : false,
            isSpacesInput: this.props.ifc.spaces,
            errorMsg: {}
        };
        this.handleEditIfcClick = this.handleEditIfcClick.bind(this);
    }

    //Validation functions:
    validateName = () => {
        const {nameInput} = this.state;
        let nameValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(nameInput === undefined || nameInput === '') {
            nameValid = false;
            errorMsg.nameInput = 'PleaseTypeInTheName'
        } else if(nameInput.length < 3) {
            nameValid = false;
            errorMsg.nameInput = 'NameNeedsToContainAtLeast3Characters'
        } else if(nameInput.length > 30) {
            nameValid = false;
            errorMsg.nameInput = 'NameCannotBeLongerThan30Characters'
        }
        this.setState({nameValid, errorMsg})
    };

    validatePrefix = () => {
        const {prefixInput} = this.state;
        let prefixValid = true;
        let errorMsg = {...this.state.errorMsg};

        if (prefixInput === undefined || prefixInput === '') {
            prefixValid = false;
            errorMsg.prefixInput = 'PleaseTypeInThePrefix(number)'
        }
        this.setState({prefixValid, errorMsg})
    };

    handleEditIfcClick = () => {
        if(this.state.nameValid !== true) {
            this.validateName()
        } else if(this.state.prefixValid !== true) {
            this.validatePrefix()
        } else {
            this.props.saveMethod(this.state.nameInput, this.state.prefixInput, this.state.isSpacesInput, this.props.ifc)
        }
    }

    render() {
        return (
            <div className='editIfcModalBackground'>
                <div className='editIfcModalWindow'>
                    <div className='editIfcModalTitle'>
                        {this.props.modalTitle}
                    </div>
                    <div className='editIfcModalForm'>
                        <label className='editIfcModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Name')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.nameInput} />
                        <input className='editIfcModalInput'
                               defaultValue={this.state.nameInput}
                               onInput={e => this.setState({nameInput: e.target.value}, this.validateName)}
                               type="text"
                        />

                        <label className='editIfcModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('LayerPart')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.prefixValid} message={this.state.errorMsg.prefixInput} />
                        <input className='editIfcModalInput'
                               defaultValue={this.state.prefixInput}
                               onInput={e => this.setState({prefixInput: e.target.value}, this.validatePrefix)}
                               type="number"
                        />

                        <label className='editIfcModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Spaces')}
                                    </span>
                                }
                            </Translation>
                        </label>
                        <input className='editIfcModalInput isSpacesEditInput'
                               checked={this.state.isSpacesInput}
                               onChange={() => this.setState({isSpacesInput: !this.state.isSpacesInput})}
                               type="checkbox"
                        />

                        <div className='ifcEditInfo'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('ifcEditInfo')}
                                    </span>
                                }
                            </Translation>
                        </div>

                        <div className='editIfcModalButtons'>
                            <button
                                className='editIfcModalButton editIfcModalButton--cancel'
                                onClick={this.props.closeMethod}
                            >
                                CANCEL
                            </button>
                            <button
                                className='editIfcModalButton editIfcModalButton--save'
                                onClick={this.handleEditIfcClick}
                            >
                                SAVE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IfcEditModal;
