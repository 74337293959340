import React from 'react';
import './LayerInfo.css';

import LayerAddModal from '../Layers/LayerAddModal/LayerAddModal';
import {getCompanyId} from "../../helpers/helpers";
import {Translation} from "react-i18next";

class LayerInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: true,
            items: [],
            error: false,
            chosenLayerId: this.props.chosenLayerId,
            showEditModal: false
        };

        this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.saveLayer = this.saveLayer.bind(this);
    }

    componentDidMount() {
        this.setState({
            chosenLayerId: this.props.chosenLayerId
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.chosenLayerId!==this.props.chosenLayerId){
            this.setState({
                chosenLayerId: this.props.chosenLayerId
            });
        }
    }

    handleOpenEditModal() {
        this.setState({
            showEditModal: true
        });
    }

    handleCloseEditModal() {
        this.setState({
            showEditModal: false
        });
    }

    async saveLayer(id, name, color, prefixes, transparent, visible) {

        this.setState({
            isLoaded: false
        });

        const response = await fetch(`${process.env.REACT_APP_API_URL}/layers/${this.props.item.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('mapperToken')
            },
            body: JSON.stringify({
                name: name,
                color: color,
                companyId: getCompanyId(),
                defaultTransparent: transparent,
                defaultVisible: visible
            })
        });

        const json = await response.json();

        let layerPartsBeforeEditIds = [];

        if(this.props.item) {
            layerPartsBeforeEditIds = this.props.item.partIds;
        }

        const layerPartsBeforeEdit = this.props.layerParts.filter((layerPart) =>
            layerPartsBeforeEditIds.indexOf(layerPart.id) > -1
        );

        const layerPartsBeforeEditNames = layerPartsBeforeEdit.map((layer) => {
            return layer.prefix
        });

        const response2 = await layerPartsBeforeEdit.forEach((prefixBeforeEdit) => {
            if(!prefixes.includes(prefixBeforeEdit.prefix)) {

                fetch(`${process.env.REACT_APP_API_URL}/custom/layerParts/${prefixBeforeEdit.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': sessionStorage.getItem('mapperToken')
                    }
                })
            }
        });

        const response3 = await prefixes.forEach((prefix) => {

            if(!layerPartsBeforeEditNames.includes(prefix)) {

                fetch(`${process.env.REACT_APP_API_URL}/layerParts/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': sessionStorage.getItem('mapperToken')
                    },
                    body: JSON.stringify({
                        layerId: json.id,
                        layer: 'layers/' + json.id,
                        prefix: prefix
                    })
                })
            }
        });

        this.handleCloseEditModal(response2, response3);
        this.props.fetchLayers(json.id);

    };

    render() {
        const { item, layerParts } = this.props;

        let layerPartsToDisplayIds = [];

        if(item) {
            layerPartsToDisplayIds = item.partIds;
        }

        const layerPartsToDisplay = layerParts.filter((layerPart) =>
                layerPartsToDisplayIds.indexOf(layerPart.id) > -1
        );

        const layerPartsNames = layerPartsToDisplay.map((layer) => {
            return layer.prefix
        });

        if (!item) {
            return null;
        } else {
            return (
                <div>
                    <div className='layerInfoHeader'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('LayerInfo')}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className='layerInfo'>
                        <div
                            className='layerInfoTitleBar'
                            id={item.id}
                        >
                            <div className='layerInfoTitle'>
                                {item.name}
                            </div>
                            <div className='layerInfoActions'>
                                <i className='material-icons actionIcon'
                                   onClick={this.handleOpenEditModal}
                                >
                                    edit
                                </i>
                                <i className='material-icons actionIcon actionIcon--delete'
                                   onClick={this.props.handleOpenDeleteModal}
                                >
                                    delete
                                </i>
                            </div>
                        </div>
                        <div className='layerInfoContent'>
                            <div className='layerInfoTop'>
                                <div className='layerInfoColorHolder'>
                                    <div className='layerInfoLabel'>
                                        <Translation>
                                            {(t, {i18n}) =>
                                                <span>
                                                    {t('Color')}
                                                </span>
                                            }
                                        </Translation>
                                    </div>
                                    <div className='layerInfoColor'>
                                        <div className='LayerInfoColorBox' style={{background: item.color}}> </div>
                                        <div className='layerInfoText'>
                                            {item.color}
                                        </div>
                                    </div>
                                </div>
                                <div className='layerInfoStatus'>
                                    <div className='layerInfoLabel'>
                                        <Translation>
                                            {(t, {i18n}) =>
                                                <span>
                                                {t('Transparent')}
                                            </span>
                                            }
                                        </Translation>
                                    </div>
                                    {item.defaultTransparent
                                        ?
                                            <i className="material-icons statusIcon statusIcon--true">
                                                check_circle
                                            </i>
                                        :
                                            <i className="material-icons statusIcon statusIcon--false">
                                                cancel
                                            </i>
                                    }
                                </div>
                                <div className='layerInfoStatus'>
                                    <div className='layerInfoLabel'>
                                        <Translation>
                                            {(t, {i18n}) =>
                                                <span>
                                                {t('Visible')}
                                            </span>
                                            }
                                        </Translation>
                                    </div>
                                    {item.defaultVisible
                                        ?
                                            <i className="material-icons statusIcon statusIcon--true">
                                                check_circle
                                            </i>
                                        :
                                            <i className="material-icons statusIcon statusIcon--false">
                                                cancel
                                            </i>
                                    }
                                </div>
                            </div>
                            <div className='layerInfoLabel--next'>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('PartsByPrefixes')}
                                        </span>
                                    }
                                </Translation>
                            </div>
                            <div className='layerInfoParts'>

                                {item.layerParts.length ?
                                    item.layerParts.map((layerPart, index) =>
                                        <div className='layerInfoPart' key={index}>
                                            {layerPart.prefix}
                                        </div>
                                    )
                                    :
                                    <Translation>
                                        {(t, {i18n}) =>
                                            <span>
                                                {t('NoLayerPartsFound')}
                                            </span>
                                        }
                                    </Translation>
                                }
                            </div>
                        </div>
                    </div>

                    {this.state.showEditModal
                        ?
                        <LayerAddModal
                            modalTitle='EditLayer'
                            closeMethod={this.handleCloseEditModal}
                            saveMethod={this.saveLayer}
                            name={item.name}
                            color={item.color}
                            prefixes={layerPartsNames}
                            transparent={item.defaultTransparent}
                            visible={item.defaultVisible}
                        />
                        :
                        null
                    }

                </div>
            );
        }
    }
}

export default LayerInfo;
