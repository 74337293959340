import React, {Component} from 'react';
import './ButtonBubbleSpinner.css';

class ButtonBubbleSpinner extends Component {

    render() {
        return (
            <div className={'buttonLoader'}/>
        );
    }
}

export default ButtonBubbleSpinner;