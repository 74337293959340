import React from 'react';
import './BuildingsDetails.css';
import 'react-tabs/style/react-tabs.css';
import {getCompanyId} from "../../helpers/helpers";
import {Translation} from "react-i18next";

import List from '../common/List/List';
import Spinner from '../common/Spinner/Spinner';
import {Tabs, Tab, TabList, TabPanel} from 'react-tabs';

import BuildingInfo from '../BuildingInfo/BuildingInfo';
import BuildingIfcs from '../BuildingIfcs/BuildingIfcs';
import BuildingAddModal from './BuildingAddModal/BuildingAddModal';
import DeleteModal from '../common/DeleteModal/DeleteModal';

class BuildingsDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: true,
            items: [],
            goToPage: 1,
            itemsPerPage: 10,
            chosenBuildingId: null,
            showAddModal: false,
            showEditModal: false,
            showDeleteModal: false,
            newBuildingId: null,
        };

        this.fetchBuildings = this.fetchBuildings.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.createBuilding = this.createBuilding.bind(this);
        this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.addPhotos = this.addPhotos.bind(this);
        this.editBuilding = this.editBuilding.bind(this);
        this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.deleteBuilding = this.deleteBuilding.bind(this);
    }

    componentDidMount() {
        this.setState({
            chosenBuildingId: this.props.selectedProject
        }, () =>
            this.fetchBuildings()
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedProject!==this.props.selectedProject){
            this.setState({
                chosenBuildingId: this.props.selectedProject
            }, () =>
                    this.fetchBuildings()
            );
        }
    }

    fetchBuildings() {

        if(this.props.selectedProject) {
            this.setState({
                isLoaded: false
            });

            fetch(`${process.env.REACT_APP_API_URL}/mapperBuildings?id=${this.props.selectedProject}`, {
                method: 'GET',
                headers: {
                    'Authorization': sessionStorage.getItem('mapperToken')
                }
            })
                .then(res => res.json())
                .then(
                    response => {
                        this.setState({
                            isLoaded: true,
                            items: response._embedded.buildings,
                            error: false
                        });
                    }
                )
                .catch((error) => {
                    this.setState({
                        isLoaded: true,
                        items: [],
                        error
                    });
                })
        }
    }

    handleOpenAddModal() {
        this.setState({
            showAddModal: true
        });
    }

    handleCloseAddModal() {
        this.setState({
            showAddModal: false
        });
    }

    createBuilding(id, name, description, location, supervisorInput, image) {

        this.setState({
            isLoaded: false
        });

        fetch(`${process.env.REACT_APP_API_URL}/mapperBuildings/`, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken'),
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                name: name,
                companyId: getCompanyId(),
                location: location,
                description: description,
                supervisorId: supervisorInput
            })
        })
            .then(res => res.json())
            .then(
                res => {
                    this.setState({
                        chosenBuildingId: res.id,
                        newBuildingId: res.id,
                        goToPage: 1
                    },() => this.addPhotos(image));
                }
            )
            .then(!image ? () => this.props.updateProjectSelector(this.state.chosenBuildingId) : null)
            .then(!image ? this.fetchBuildings : null)
            .then(this.handleCloseAddModal)

    }

    addPhotos(image) {

        if(image) {
            const formData = new FormData();
            formData.append('file', image, image.name);

            fetch(`${process.env.REACT_APP_API_URL}/custom/buildings/${this.state.newBuildingId}/images/`, {
                method: 'POST',
                headers: {
                    'Authorization': sessionStorage.getItem('mapperToken')
                },
                body: formData
            })
                .then(() => this.props.updateProjectSelector(this.state.newBuildingId))
                .then(this.fetchBuildings)
        }
    }

    handleOpenEditModal(e) {
        const editedItemId = e.target.parentElement.parentElement.id;
        const index = this.state.items.findIndex(x => x.id === Number(editedItemId));

        const editedItemName = this.state.items[index].name;
        const editedItemLocation = this.state.items[index].location;
        const editedItemDescription = this.state.items[index].description;
        const editedItemImageUrl = this.state.items[index].imageUrl;
        const editedItemSupervisor = this.state.items[index].supervisorId

        this.setState({
            editedItemId: editedItemId,
            editedItemName: editedItemName,
            editedItemLocation: editedItemLocation,
            editedItemDescription: editedItemDescription,
            editedItemImageUrl: editedItemImageUrl,
            editedItemSupervisor: editedItemSupervisor,
            showEditModal: true
        });
    }

    handleCloseEditModal() {
        this.setState({
            showEditModal: false
        });
    }

    editBuilding(id, name, description, location, supervisorInput, image, imageUrl) {

        this.setState({
            isLoaded: false
        });

        fetch(`${process.env.REACT_APP_API_URL}/mapperBuildings/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': sessionStorage.getItem('mapperToken')
            },
            body: JSON.stringify({
                name: name,
                companyId: getCompanyId(),
                location: location,
                description: description,
                supervisorId: supervisorInput,
                imageUrl: imageUrl
            })
        })
            .then(res => res.json())
            .then(
                response => {
                    this.setState({
                        chosenBuildingId: response.id,
                        newBuildingId: response.id,
                        goToPage: 1
                    },() => this.addPhotos(image));
                }
            )
            .then(!image ? () => this.props.updateProjectSelector(this.state.newBuildingId) : null)
            .then(this.handleCloseEditModal)
            .then(!image ? this.fetchBuildings : null)
    }

    handleOpenDeleteModal(e) {
        const editedItemName = e.target.parentElement.parentElement.children[0].innerText;
        const editedItemId = e.target.parentElement.parentElement.id;

        this.setState({
            editedItemName: editedItemName,
            editedItemId: editedItemId,
            showDeleteModal: true
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    deleteBuilding() {

        const deleteItemId = this.state.editedItemId;

        const index = this.state.items.map(function(e) {return e.id; }).indexOf(deleteItemId);
        const page = index === -1 ? 1 : Math.floor(index / this.state.itemsPerPage) + 1;

        const apiUrl = `${process.env.REACT_APP_API_URL}/mapperBuildings/` + deleteItemId;

        fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(this.fetchBuildings)
            .then(
                this.setState({
                    goToPage: page
                })
            )
            .then(this.props.updateProjectSelector)
            .then(this.handleCloseDeleteModal)
    }

    handleListItemClick(e) {
        this.setState({
            chosenBuildingId: Number(e.target.parentElement.id)
        });
    }

    render() {
        const { error, isLoaded, items, chosenBuildingId } = this.state;
        const { selectedProject } = this.props;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <Spinner/>;
        } else {
            return (
                <div className='page'>
                    <div className='pageHeader'>
                        <Translation>
                            {(t, {i18n}) =>
                                <div>
                                    {t('BuildingDetails')}
                                </div>
                            }
                        </Translation>
                        <div className='addButtonWrapper'>
                            <i className='material-icons createIcon'
                               onClick={this.handleOpenAddModal}
                            >
                                add
                            </i>
                        </div>
                    </div>
                    {items.length ?
                        <div className='pageContent'>
                            <div className='pageContentColumn1-2'>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <List
                                            items={items}
                                            filter={true}
                                            filterValue={selectedProject}
                                            filterField='id'
                                            columnsTitles={[t('Name'), t('Created')]}
                                            columnsSources={['name', 'createdAt']}
                                            dateColumn='2'
                                            edit={false}
                                            download={false}
                                            delete={false}
                                            currentPage={this.state.goToPage}
                                            itemsPerPage={this.state.itemsPerPage}
                                            searchValue={this.props.searchValue}
                                            handleListItemClick={this.handleListItemClick}
                                            chosenId={this.state.chosenBuildingId}
                                        />
                                    }
                                </Translation>
                            </div>
                            <div className='pageContentColumn2-2'>
                                <Tabs>
                                    <TabList>
                                        <Tab>
                                            <Translation>
                                                {(t, {i18n}) =>
                                                    <span>{t('BuildingInfo')}</span>
                                                }
                                            </Translation>
                                        </Tab>
                                        <Tab>
                                            <Translation>
                                                {(t, {i18n}) =>
                                                    <span>{t('IfcsList')}</span>
                                                }
                                            </Translation>
                                        </Tab>
                                    </TabList>

                                    <TabPanel className='tabPanel'>
                                        <BuildingInfo
                                            items={items}
                                            selectedProject={selectedProject}
                                            chosenBuildingId={chosenBuildingId}
                                            editMethod={this.handleOpenEditModal}
                                            deleteMethod={this.handleOpenDeleteModal}
                                        />
                                    </TabPanel>
                                    <TabPanel className='tabPanel'>
                                        <BuildingIfcs
                                            items={items}
                                            selectedProject={selectedProject}
                                            chosenBuildingId={chosenBuildingId}
                                        />
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                        : <div className='infoBox'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('PleaseSelectAProject')}
                                    </span>
                                }
                            </Translation>
                        </div>
                    }

                    {this.state.showAddModal
                        ?
                        <BuildingAddModal
                            modalTitle='AddNewBuilding'
                            closeMethod={this.handleCloseAddModal}
                            saveMethod={this.createBuilding}
                        />
                        :
                        null
                    }

                    {this.state.showEditModal
                        ?
                        <BuildingAddModal
                            modalTitle='EditBuildingInfo'
                            id={this.state.editedItemId}
                            name={this.state.editedItemName}
                            location={this.state.editedItemLocation}
                            description={this.state.editedItemDescription}
                            imageUrl={this.state.editedItemImageUrl}
                            supervisorId={this.state.editedItemSupervisor}
                            closeMethod={this.handleCloseEditModal}
                            saveMethod={this.editBuilding}
                        />
                        :
                        null
                    }

                    {this.state.showDeleteModal
                        ?
                        <DeleteModal
                            itemName={this.state.editedItemName}
                            closeMethod={this.handleCloseDeleteModal}
                            deleteMethod={this.deleteBuilding}
                        />
                        :
                        null
                    }
                </div>
            );
        }
    }
}

export default BuildingsDetails;
