import React from 'react';
import './Rooms.css';

import List from '../common/List/List';
import Spinner from '../common/Spinner/Spinner';
import {Tab} from "react-tabs";
import {Translation} from "react-i18next";

class Rooms extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            goToPage: 1,
            itemsPerPage: 10,
            chosenFloorId: null,
            chosenRoomId: null
        };

        this.fetchRooms = this.fetchRooms.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            chosenFloorId: this.props.chosenFloorId
        }, () =>
            this.fetchRooms()
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.chosenFloorId!==this.props.chosenFloorId){
            this.fetchRooms();
        }
    }

    fetchRooms() {
        this.setState({
            isLoaded: false
        });

        fetch(`${process.env.REACT_APP_API_URL}/mapperRooms?floorId=${this.props.chosenFloorId}`)
            .then(res => res.json())
            .then(
                response => {
                    this.setState({
                        isLoaded: true,
                        items: response._embedded.rooms,
                        error: false
                    },() => {
                            if (this.state.items.length !== 0) {
                                this.setState({
                                    chosenRoomId: this.state.items[0].id
                                })
                            }
                        }
                    );
                }
            )
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                    items: [],
                    error
                });
            })
    }

    handleListItemClick(e) {
        this.setState({
            chosenRoomId: Number(e.target.parentElement.id)
        });
    }

    render() {
        const { error, isLoaded, items } = this.state;
        const { chosenFloorId } = this.props;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <Spinner/>;
        } else if (!items.length) {
            return (
                <div className='noResults'>
                    <Tab>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('NoResultsFound')}
                                </span>
                            }
                        </Translation>
                    </Tab>
                </div>
            )
        } else {
            return (
                <Translation>
                    {(t, {i18n}) =>
                        <List
                            items={items}
                            filter={true}
                            filterValue={chosenFloorId}
                            filterField='floorId'
                            columnsTitles={[t('Name'), t('Ready')]}
                            columnsSources={['name', 'ready']}
                            booleanColumns={[2]}
                            edit={false}
                            download={true}
                            downloadUrl='modelUrl'
                            delete={false}
                            currentPage={this.state.goToPage}
                            itemsPerPage={this.state.itemsPerPage}
                            searchValue={this.props.searchValue}
                            handleListItemClick={this.handleListItemClick}
                        />
                    }
                </Translation>
            );
        }
    }
}

export default Rooms;
