import React from 'react';
import './BuildingIfcs.css';

import BuildingIfcsListItem from './BuildingIfcsListItem';
import UploadBar from '../common/UploadBar/UploadBar';
import IfcEditModal from './IfcEditModal/IfcEditModal';
import AlertModal from "../common/AlertModal/AlertModal";
import {Translation} from "react-i18next";
import Spinner from "../common/Spinner/Spinner";

class BuildingIfcs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chosenBuildingId: null,
            spacesIfcInputToRender: null,
            ifcInputsToRender: [],
            isLoaded: true,
            ifcs: [],
            error: false,
            showEditModal: false,
            editedIfcId: null,
            showUploadBar: true,
            showUploadOptions: false,
            files: [],
            uploadedIfcPrefix: '',
            uploadedIfcIsSpaces: false,
            showUploadConfirmationModal: false,
            showUploadCompleteConfirmation: false,
            showSpacesIfcAlert: false
        };

        this.fetchIfcs = this.fetchIfcs.bind(this);
        this.getIfcInputs = this.getIfcInputs.bind(this);
        this.uploadIfcFiles = this.uploadIfcFiles.bind(this);
        this.handleOpenIfcEditModal = this.handleOpenIfcEditModal.bind(this);
        this.handleCloseIfcEditModal = this.handleCloseIfcEditModal.bind(this);
        this.editIfc = this.editIfc.bind(this);
        this.generateBuilding = this.generateBuilding.bind(this);
        this.hideUploadConfirmationModal = this.hideUploadConfirmationModal.bind(this);
        this.showUploadCompleteConfirmation = this.showUploadCompleteConfirmation.bind(this);
        this.hideUploadCompleteConfirmation = this.hideUploadCompleteConfirmation.bind(this);
        this.showSpacesIfcAlert = this.showSpacesIfcAlert.bind(this);
        this.hideSpacesIfcAlert = this.hideSpacesIfcAlert.bind(this);
        this.showUploadOptions = this.showUploadOptions.bind(this);
        this.hideUploadOptions = this.hideUploadOptions.bind(this);
        this.formatFileSize = this.formatFileSize.bind(this);
    }

    componentDidMount() {
        this.setState({
            chosenBuildingId: this.props.chosenBuildingId
        }, () =>
            this.fetchIfcs()
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.chosenBuildingId!==this.props.chosenBuildingId){
            this.setState({
                chosenBuildingId: this.props.chosenBuildingId
            }, () =>
            this.fetchIfcs()
            );
        }
    }

    fetchIfcs() {

        console.log('fetch');

        if(this.state.chosenBuildingId) {

            this.setState({
                isLoaded: false
            });

            fetch(`${process.env.REACT_APP_API_URL}/ifcFiles?buildingId=${this.state.chosenBuildingId}&sort=prefix,asc`, {
                method: 'GET',
                headers: {
                    'Authorization': sessionStorage.getItem('mapperToken')
                }
            })
                .then(res => res.json())
                .then(
                    response => {
                        this.setState({
                            isLoaded: true,
                            ifcs: response._embedded.ifcFiles,
                            error: false
                        });
                    }
                )
                .catch((error) => {
                    this.setState({
                        isLoaded: true,
                        ifcs: [],
                        error
                    });
                })
        }
    }

    showUploadOptions(files) {
        this.setState({
            showUploadBar: false,
            showUploadOptions: true,
            files: files
        })
    }

    hideUploadOptions() {
        this.setState({
            showUploadOptions: false,
            showUploadBar: true,
            files: [],
            uploadedIfcPrefix: '',
            uploadedIfcIsSpaces: false
        })
    }

    formatFileSize(bytes, decimalPoint) {
        if(bytes === 0) return '0 Bytes';
        const k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    uploadIfcFiles(files) {

        const formData = new FormData();

        formData.append('properties', new Blob([JSON.stringify({
            name: files[0].name,
            prefix: this.state.uploadedIfcPrefix,
            buildingId: this.state.chosenBuildingId,
            isSpaces: this.state.uploadedIfcIsSpaces
        })], {
            type: "application/json"
        }));

        formData.append('file', files[0], files[0].name);

        this.hideUploadOptions();

        fetch(`${process.env.REACT_APP_API_URL}/ifc_files/upload`, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            },
            body: formData,
        })
            .then(this.setState({
                showUploadConfirmationModal: true
            }))
            .then(this.fetchIfcs)
            .then(this.showUploadCompleteConfirmation)
    }

    hideUploadConfirmationModal() {
        this.setState({
            showUploadConfirmationModal: false
        })
    }

    showUploadCompleteConfirmation() {
        this.setState({
            showUploadConfirmationModal: false,
            showUploadCompleteConfirmation: true
        })
    }

    hideUploadCompleteConfirmation() {
        this.setState({
            showUploadCompleteConfirmation: false
        })
    }

    getIfcInputs(isSpaces, isChecked, ifcId) {

        if(isChecked) {

            if(isSpaces && !this.state.spacesIfcInputToRender) {
                this.setState({
                    spacesIfcInputToRender: ifcId
                })
            } else {

                let ifcInputs = this.state.ifcInputsToRender;

                let found = false;
                let existingIfcIndex = null;

                ifcInputs.forEach((ifc, index) => {
                    if (ifc === ifcId) {
                        found = true;
                        existingIfcIndex = index;
                    }
                });

                const newIfcInput = ifcId;

                !found
                    ? ifcInputs.push(newIfcInput)
                    : ifcInputs.splice(existingIfcIndex, 1, newIfcInput);

                this.setState({
                    ifcInputsToRender: ifcInputs
                });
            }
        }

        if(!isChecked) {

            if(isSpaces) {
                this.setState({
                    spacesIfcInputToRender: null
                })
            } else {

                let ifcInputs = this.state.ifcInputsToRender;

                let found = false;
                let existingIfcIndex = null;

                ifcInputs.forEach((ifc, index) => {
                    if (ifc === ifcId) {
                        found = true;
                        existingIfcIndex = index;
                    }
                });

                if(found) {
                    ifcInputs.splice(existingIfcIndex, 1);

                    this.setState({
                        ifcInputsToRender: ifcInputs
                    });
                }
            }
        }
    }

    showSpacesIfcAlert() {
        this.setState({
            showSpacesIfcAlert: true
        })
    }

    hideSpacesIfcAlert() {
        this.setState({
            showSpacesIfcAlert: false
        })
    }

    handleOpenIfcEditModal(ifc) {
        this.setState({
            showEditModal: true,
            editedIfc: ifc
        });
    }

    handleCloseIfcEditModal() {
        this.setState({
            showEditModal: false
        });
    }

    editIfc(name, prefix, isSpaces, ifc) {

        this.setState({
            isLoaded: false,
            ifcs: [],
            spacesIfcInputToRender: null,
            ifcInputsToRender: [],
        });

        fetch(`${process.env.REACT_APP_API_URL}/ifcFiles/${ifc.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': sessionStorage.getItem('mapperToken')
            },
            body: JSON.stringify({
                name: name,
                prefix: prefix,
                url: ifc.url,
                spaces: isSpaces,
                buildingId: ifc.buildingId
            })
        })
            .then(res => res.json())
            .then(this.handleCloseIfcEditModal)
            .then(this.fetchIfcs)
    }

    generateBuilding(e) {
        e.preventDefault();

        if(e.target.classList.contains('generateBuildingButton--active')) {

            fetch(`${process.env.REACT_APP_API_URL}/extractor/start`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': sessionStorage.getItem('mapperToken')
                },
                body: JSON.stringify({
                    buildingId: this.props.chosenBuildingId,
                    spacesIFCId: this.state.spacesIfcInputToRender,
                    elementIFCIds: this.state.ifcInputsToRender
                })
            })
                .then(
                    this.setState({
                        spacesIfcInputToRender: null,
                        ifcInputsToRender: [],
                        ifcs: []
                    },() => this.fetchIfcs())
                )
        }
    }

    render() {
        const { items, selectedProject, chosenBuildingId } = this.props;
        const { isLoaded, ifcs, spacesIfcInputToRender, ifcInputsToRender, uploadedIfcPrefix, uploadedIfcIsSpaces, showUploadBar, showUploadOptions, files } = this.state;

        const currentBuilding = chosenBuildingId ? (items.filter(item => item.id === chosenBuildingId))[0] : (items.filter(item => item.id === selectedProject))[0];

        if (!currentBuilding) {
            return null;
        } else if (!isLoaded) {
            return <Spinner/>;
        } else {
            return (
                <div className='buildingIfcs'>
                    <div className='ifcUploadComponent'>
                        {showUploadBar ?
                            <UploadBar
                                uploadBarText={
                                    <Translation>
                                        {(t, {i18n}) =>
                                            <span>
                                                {t('AddIfcFile')}
                                            </span>
                                        }
                                    </Translation>
                                }
                                sendMethod={this.showUploadOptions}
                            />
                            : null
                        }
                        {showUploadOptions ?
                            <div className='ifcUploadOptionsBox'>
                                <div className='ifcUploadFileInfo'>
                                    <div className='ifcUploadFileName'>
                                        {files[0].name}
                                    </div>
                                    <div className='IfcUploadFileSize'>
                                        {this.formatFileSize(files[0].size, 1)}
                                    </div>
                                </div>
                                <div className='ifcUploadOptionsWrapper'>
                                    <div className='ifcUploadOptions'>
                                        <div className='ifcUploadOption'>
                                            <label className='ifcUploadOptionLabel'>
                                                <Translation>
                                                    {(t, {i18n}) =>
                                                        <span>
                                                            {t('LayerPart')}
                                                        </span>
                                                    }
                                                </Translation>
                                            </label>
                                            <input
                                                className='ifcUploadOptionInput'
                                                type='text'
                                                value={uploadedIfcPrefix}
                                                onChange={e => this.setState({uploadedIfcPrefix: e.target.value})}
                                            />
                                        </div>
                                        <div className='ifcUploadOption'>
                                            <input
                                                className='ifcUploadOptionInput--checkbox'
                                                type='checkbox'
                                                onChange={() => this.setState({uploadedIfcIsSpaces: !uploadedIfcIsSpaces})}
                                            />
                                            <label className='ifcUploadOptionLabel'>
                                                <Translation>
                                                    {(t, {i18n}) =>
                                                        <span>
                                                            {t('Spaces')}
                                                        </span>
                                                    }
                                                </Translation>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='ifcUploadOptionsButtons'>
                                        <button
                                            className={uploadedIfcPrefix ? 'ifcUploadButton ifcUploadButton--active' : 'ifcUploadButton'}
                                            onClick={uploadedIfcPrefix ? () => this.uploadIfcFiles(files) : null}
                                        >
                                            <Translation>
                                                {(t, {i18n}) =>
                                                    <span>
                                                        {t('Add').toUpperCase()}
                                                    </span>
                                                }
                                            </Translation>
                                        </button>
                                        <i
                                            className='material-icons actionIcon--closeUploadOptions'
                                            onClick={this.hideUploadOptions}
                                        >
                                            cancel
                                        </i>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    {ifcs.length ?
                        <div>
                            <table className='buildingIfcsTable'>
                                <thead className='buildingIfcsTableHeaderWrapper'>
                                    <tr className='buildingIfcsTableHeader'>
                                        <th
                                            className={'buildingIfcsTableHeaderField buildingIfcsTableHeaderField--column1'}
                                        >
                                            <Translation>
                                                {(t, {i18n}) =>
                                                    <span>
                                                        {t('Name')}
                                                    </span>
                                                }
                                            </Translation>
                                        </th>
                                        <th
                                            className={'buildingIfcsTableHeaderField buildingIfcsTableHeaderField--column2'}
                                        >
                                            <Translation>
                                                {(t, {i18n}) =>
                                                    <span>
                                                        {t('LayerPart')}
                                                    </span>
                                                }
                                            </Translation>
                                        </th>
                                        <th
                                            className={'buildingIfcsTableHeaderField buildingIfcsTableHeaderField--column3'}
                                        >
                                            <Translation>
                                                {(t, {i18n}) =>
                                                    <span>
                                                        {t('Spaces')}
                                                    </span>
                                                }
                                            </Translation>
                                        </th>
                                        <th className='buildingIfcsTableHeaderField buildingIfcsTableHeaderField--actions'/>
                                    </tr>
                                </thead>
                                <tbody className={'buildingIfcsTableContent'}>
                                    {ifcs.map((ifc, index) => (
                                        <BuildingIfcsListItem
                                            key={index}
                                            id={ifc.id}
                                            ifc={ifc}
                                            getIfcInputs={this.getIfcInputs}
                                            editMethod={this.handleOpenIfcEditModal}
                                            spacesIfcInputToRender={this.state.spacesIfcInputToRender}
                                            showSpacesIfcAlert={this.showSpacesIfcAlert}
                                        />
                                    ))}
                                </tbody>
                            </table>
                            <Translation>
                                {(t, {i18n}) =>
                                    <button
                                        className={!ifcInputsToRender.length || !spacesIfcInputToRender
                                            ? 'generateBuildingButton'
                                            : 'generateBuildingButton generateBuildingButton--active'
                                        }
                                        onClick={this.generateBuilding}
                                    >
                                        {t('GenerateBuilding').toUpperCase()}
                                    </button>
                                }
                            </Translation>
                        </div>
                        : null
                    }

                    {this.state.showEditModal
                        ?
                        <IfcEditModal
                            modalTitle='Edit IFC file'
                            ifc={this.state.editedIfc}
                            closeMethod={this.handleCloseIfcEditModal}
                            saveMethod={this.editIfc}
                        />
                        :
                        null
                    }

                    {this.state.showUploadConfirmationModal
                        ?
                        <AlertModal
                            alert='ifcUploadConfirmationMessage'
                            closeMethod={this.hideUploadConfirmationModal}
                        />
                        :
                        null
                    }

                    {this.state.showUploadCompleteConfirmation
                        ?
                        <AlertModal
                            alert='ifcUploadCompleteMessage'
                            closeMethod={this.hideUploadCompleteConfirmation}
                        />
                        :
                        null
                    }


                    {this.state.showSpacesIfcAlert
                        ?
                        <AlertModal
                            alert='multipleSpacesIfcsWarning'
                            closeMethod={this.hideSpacesIfcAlert}
                        />
                        :
                        null
                    }
                </div>
            );
        }
    }
}

export default BuildingIfcs;
