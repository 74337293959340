import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import './App.css';
import {getTokenExp} from "./helpers/helpers";

import LoginPage from './components/LoginPage/LoginPage';
import ResetPasswordPage from './components/PasswordReset/ResetPasswordPage';
import SetPasswordPage from './components/PasswordReset/SetPasswordPage';
import MainPage from './layout/MainPage/MainPage';

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        (sessionStorage.getItem('mapperToken') && (getTokenExp() > Date.now() / 1000))
        ? <Component {...props}/>
        : <Redirect to='/login'/>
    )}/>
);

class App extends React.Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route
                        path='/login'
                        render={(props) =>
                            <LoginPage
                                {...props}
                            />}
                    />
                    <Route
                        path='/request_reset'
                        render={(props) =>
                            <ResetPasswordPage
                                {...props}
                            />}
                    />
                    <Route
                        path='/reset'
                        render={(props) =>
                            <SetPasswordPage
                                {...props}
                            />}
                    />
                    <PrivateRoute
                        path='/'
                        component={(props) =>
                            <MainPage
                                {...props}
                            />
                        }
                    />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
