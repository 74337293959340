import React from 'react';
import './DefaultMessages.css';
import { getCompanyId } from '../../helpers/helpers';
import {Translation} from "react-i18next";

function ValidationMessage(props) {
    if (!props.valid && props.message) {
        return(
            <Translation>
                {(t, {i18n}) => <div className='error-msg'>{t(`${props.message}`)}</div>}
            </Translation>
        )
    }
    return null;
}

class DefaultMeassage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: true,
            items: [],
            error: false,
            chosenDefaultMessageId: this.props.chosenDefaultMessageId,
            defaultMessageInput: '', defaultMessageValid: true,
            errorMsg: {}
        };

        this.fetchDefaultMessage = this.fetchDefaultMessage.bind(this);
        this.saveDefaultMessage = this.saveDefaultMessage.bind(this);
    }

    componentDidMount() {
        if(this.props.chosenDefaultMessage) {
            this.setState({
                chosenDefaultMessageId: this.props.chosenDefaultMessageId,
                chosenDefaultMessage: this.props.chosenDefaultMessage
            },() => document.getElementById('defaultMessageText').value = this.state.chosenDefaultMessage.content);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.chosenDefaultMessage!==this.props.chosenDefaultMessage){
            if(this.props.chosenDefaultMessageId) {
                this.setState({
                    chosenDefaultMessageId: this.props.chosenDefaultMessageId,
                    chosenDefaultMessage: this.props.chosenDefaultMessage
                },() => document.getElementById('defaultMessageText').value = this.state.chosenDefaultMessage.content);
            }
        }
    }

    fetchDefaultMessage() {

        this.setState({
            isLoaded: false
        });

        if(this.state.chosenDefaultMessageId) {

            fetch(`${process.env.REACT_APP_API_URL}/defaultMessages/${this.props.chosenDefaultMessageId}`, {
                method: 'GET',
                headers: {
                    'Authorization': sessionStorage.getItem('mapperToken')
                }
            })
                .then(res => res.json())
                .then(
                    response => {
                        this.setState({
                                isLoaded: true,
                                items: response,
                                error: false,
                                defaultMessageInput: '',
                                chosenDefaultMessageId: null
                            }, () => document.getElementById('defaultMessageText').value = this.state.items.content
                        );
                    }
                )
                .catch((error) => {
                    this.setState({
                        isLoaded: true,
                        items: [],
                        error
                    });
                })
        }
    }

    async saveDefaultMessage(e) {
        e.preventDefault();

        if(this.state.defaultMessageValid !== true) {
            this.validateDefaultMessage()
        } else if(e.target.classList.contains('saveDefaultMessageButton--active')) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/defaultMessages/${this.props.chosenDefaultMessageId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': sessionStorage.getItem('mapperToken')
                },
                body: JSON.stringify({
                    content: this.state.defaultMessageInput,
                    companyId: getCompanyId()
                })
            });

            const json = await response.json();

            this.props.fetchDefaultMessages(json.id);
        }
    }
    
    //Validate functions
    validateDefaultMessage = () => {
        const {defaultMessageInput} = this.state;
        let defaultMessageValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(defaultMessageInput.length > 512) {
            defaultMessageValid = false;
            errorMsg.defaultMessageInput = 'MessageCannotExceed512Characters'
        }
        this.setState({defaultMessageValid, errorMsg})
    };
    
    render() {

        return (
            <div className='defaultMessage'>
                <div
                    className='defaultMessageTitleBar'
                    id={this.state.items.id}
                >
                    <div className='defaultMessageTitle'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('EditMessage')}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className='defaultMessageActions'>
                        <i className='material-icons actionIcon actionIcon--delete'
                           onClick={this.props.openDeleteModalMethod}
                        >
                            delete
                        </i>
                    </div>
                </div>
                <ValidationMessage valid={this.state.defaultMessageValid} message={this.state.errorMsg.defaultMessageInput} />
                <textarea
                    id='defaultMessageText'
                    className='defaultMessageContent'
                    onInput={e => this.setState({defaultMessageInput: e.target.value}, this.validateDefaultMessage)}
                />
                <button
                    className={!this.state.defaultMessageInput
                        ? 'saveDefaultMessageButton'
                        : 'saveDefaultMessageButton saveDefaultMessageButton--active'
                    }
                    onClick={this.saveDefaultMessage}
                >
                    <Translation>
                        {(t, {i18n}) =>
                            <span>
                                {t('Save').toUpperCase()}
                            </span>
                        }
                    </Translation>
                </button>
            </div>
        );
    }
}

export default DefaultMeassage;
