import React from 'react';
import { NavLink } from 'react-router-dom';
import './SideBar.css';

import buildingsDetailsIcon from  '../../assets/icons/Buildings.png';
import floorsIcon from  '../../assets/icons/Floors.png';
import {isAdmin, isSuperAdmin, isDesigner} from '../../helpers/helpers';
import {Translation} from "react-i18next";

class SideBar extends React.Component {

    logout() {
        sessionStorage.clear();
    }

    render() {

        return (
            <nav className = 'appNav'>
                <ul className = 'menu'>
                    <li className = 'menuItem'>
                        <NavLink exact to = '/buildingsDetails'>
                            <div className='menuItemIconHolder'>
                                <img
                                    className='menuItemIcon'
                                    src={buildingsDetailsIcon}
                                    alt='Buildings Details icon'
                                />
                            </div>
                            <Translation>
                                {(t, {i18n}) =>
                                    <div className='menuItemLabel'>
                                        {t('BuildingDetails')}
                                    </div>
                                }
                            </Translation>
                        </NavLink>
                    </li>
                    {isAdmin() || isSuperAdmin() || isDesigner() ?
                        <li className='menuItem'>
                            <NavLink exact to='/layers'>
                                <div className='menuItemIconHolder'>
                                    <i
                                        className='material-icons menuItemMaterialIcon'
                                    >
                                        layers
                                    </i>
                                </div>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <div className='menuItemLabel'>
                                            {t('Layers')}
                                        </div>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        : null
                    }
                    {this.props.selectedProject ?
                        <li className = 'menuItem'>
                            <NavLink exact to = '/floors'>
                                <div className='menuItemIconHolder'>
                                    <img
                                        className='menuItemIcon'
                                        src={floorsIcon}
                                        alt='Floors icon'
                                    />
                                </div>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <div className='menuItemLabel'>
                                            {t('Floors')}
                                        </div>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        : null
                    }
                    {this.props.selectedProject ?
                        <li className = 'menuItem'>
                            <NavLink exact to = '/MessageBoard'>
                                <div className='menuItemIconHolder'>
                                    <i
                                        className='material-icons menuItemMaterialIcon'
                                    >
                                        dashboard
                                    </i>
                                </div>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <div className='menuItemLabel'>
                                            {t('MessageBoard')}
                                        </div>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        : null
                    }
                    <li className = 'menuItem'>
                        <NavLink exact to = '/defaultMessages'>
                            <div className='menuItemIconHolder'>
                                <i
                                    className='material-icons menuItemMaterialIcon'
                                >
                                    chat
                                </i>
                            </div>
                            <Translation>
                                {(t, {i18n}) =>
                                    <div className='menuItemLabel'>
                                        {t('DefaultMessages')}
                                    </div>
                                }
                            </Translation>
                        </NavLink>
                    </li>
                    <li
                        className={this.props.sideBarLabelsShow
                            ? 'menuSeparator menuSeparator--sideBarActive'
                            : 'menuSeparator'}
                    />
                    {isAdmin() || isSuperAdmin() ?
                        <li className = 'menuItem'>
                            <NavLink exact to = '/companies'>
                                <div className='menuItemIconHolder'>
                                    <i
                                        className='material-icons menuItemMaterialIcon'
                                    >
                                        business
                                    </i>
                                </div>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <div className='menuItemLabel'>
                                            {t('Companies')}
                                        </div>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        : null
                    }
                    {isAdmin() || isSuperAdmin() ?
                        <li className='menuItem'>
                            <NavLink exact to='/users'>
                                <div className='menuItemIconHolder'>
                                    <i
                                        className='material-icons menuItemMaterialIcon'
                                    >
                                        people
                                    </i>
                                </div>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <div className='menuItemLabel'>
                                            {t('Users')}
                                        </div>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        : null
                    }
                    <li className = 'menuItem'>
                        <NavLink
                            exact to = '/login'
                            onClick={this.logout}
                        >
                            <div className='menuItemIconHolder'>
                                <i
                                    className='material-icons menuItemMaterialIcon'
                                >
                                    power_settings_new
                                </i>
                            </div>
                            <Translation>
                                {(t, {i18n}) =>
                                    <div className='menuItemLabel'>
                                        {t('LogOut')}
                                    </div>
                                }
                            </Translation>
                        </NavLink>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default SideBar;
