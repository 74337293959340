import React from 'react';
import '../BuildingsDetails/BuildingsDetails.css';
import './DefaultMessages.css';
import {getCompanyId} from '../../helpers/helpers';

import List from '../common/List/List';
import Spinner from '../common/Spinner/Spinner';
import DefaultMessage from './DefaultMessage';
import DefaultMessageAddModal from './DefultMessageAddModal/DefaultMessageAddModal';
import DeleteModal from "../common/DeleteModal/DeleteModal";
import {Translation} from "react-i18next";

class DefaultMessages extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: true,
            items: [],
            goToPage: 1,
            itemsPerPage: 10,
            chosenDefaultMessageId: null,
            showAddModal: false,
            showDeleteModal: false,
            editedItemId: null,
            editedItemName: 'default message'
        };

        this.fetchDefaultMessages = this.fetchDefaultMessages.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.createDefaultMessage = this.createDefaultMessage.bind(this);
        this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.deleteDefaultMessage = this.deleteDefaultMessage.bind(this);
    }

    componentDidMount() {
        this.fetchDefaultMessages()
    }

    fetchDefaultMessages(id) {

        this.setState({
            isLoaded: false
        });

        fetch(`${process.env.REACT_APP_API_URL}/defaultMessages`, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(res => res.json())
            .then(
                response => {

                    if (response._embedded.defaultMessages.length !== 0) {

                        const index = response._embedded.defaultMessages.map(function(e) {return e.id; }).indexOf(id);
                        const page = index === -1 ? 1 : Math.floor(index / this.state.itemsPerPage) + 1;

                        !(index === -1)  ?
                            this.setState({
                                chosenDefaultMessageId: id,
                                chosenDefaultMessage: response._embedded.defaultMessages.find(x => x.id === id),
                                goToPage: page,
                                isLoaded: true,
                                items: response._embedded.defaultMessages,
                                error: false
                            })
                            :
                            this.setState({
                                chosenDefaultMessageId: response._embedded.defaultMessages[0].id,
                                chosenDefaultMessage: response._embedded.defaultMessages[0],
                                goToPage: page,
                                isLoaded: true,
                                items: response._embedded.defaultMessages,
                                error: false
                            })
                    } else {
                        this.setState({
                            isLoaded: true
                        });
                    }
                }
            )
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                    items: [],
                    error
                });
            })
    }

    handleOpenAddModal() {
        this.setState({
            showAddModal: true
        });
    }

    handleCloseAddModal() {
        this.setState({
            showAddModal: false
        });
    }

    async createDefaultMessage(content) {

        this.setState({
            isLoaded: false
        });

        const response = await fetch(`${process.env.REACT_APP_API_URL}/defaultMessages/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': sessionStorage.getItem('mapperToken')
            },
            body: JSON.stringify({
                content: content,
                companyId: getCompanyId()
            })
        });

        const json = await response.json();

        this.handleCloseAddModal();
        this.fetchDefaultMessages(json.id)
    }

    handleOpenDeleteModal() {

        this.setState({
            showDeleteModal: true
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    deleteDefaultMessage() {

        const deleteItemId = this.state.chosenDefaultMessageId;
        const apiUrl = `${process.env.REACT_APP_API_URL}/defaultMessages/` + deleteItemId;

        fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(this.fetchDefaultMessages)
            .then(this.handleCloseDeleteModal)
    }

    handleListItemClick(e) {

        const index = this.state.items.map(function(e) {return e.id; }).indexOf(Number(e.target.parentElement.id));
        const page = index === -1 ? 1 : Math.floor(index / this.state.itemsPerPage) + 1;

        if(e.target.tagName.toLowerCase() !== 'a') {
            this.setState({
                chosenDefaultMessageId: Number(e.target.parentElement.id),
                chosenDefaultMessage: this.state.items.find(x => x.id === Number(e.target.parentElement.id)),
                goToPage: page
            });
        }
    }

    render() {
        const { error, isLoaded, items, chosenDefaultMessageId } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <Spinner/>;
        } else {
            return (
                <div className='page'>
                    <div className='pageHeader'>
                        <div>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('DefaultMessages')}
                                    </span>
                                }
                            </Translation>
                        </div>
                        <div className='addButtonWrapper'>
                            <i className='material-icons createMessageIcon'
                               onClick={this.handleOpenAddModal}
                            >
                                add
                            </i>
                        </div>
                    </div>
                    {items.length ?
                        <div className='pageContent'>
                            <div className='pageContentColumn1-2'>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <List
                                            items={items}
                                            filter={true}
                                            columnsTitles={[t('Content')]}
                                            columnsSources={['content']}
                                            edit={false}
                                            download={false}
                                            delete={false}
                                            currentPage={this.state.goToPage}
                                            itemsPerPage={this.state.itemsPerPage}
                                            searchValue={this.props.searchValue}
                                            handleListItemClick={this.handleListItemClick}
                                            chosenId={chosenDefaultMessageId}
                                        />
                                    }
                                </Translation>
                            </div>
                            <div className='pageContentColumn2-2'>
                                <DefaultMessage
                                    chosenDefaultMessageId={this.state.chosenDefaultMessageId}
                                    chosenDefaultMessage={this.state.chosenDefaultMessage}
                                    fetchDefaultMessages={this.fetchDefaultMessages}
                                    openDeleteModalMethod={this.handleOpenDeleteModal}
                                />
                            </div>
                        </div>
                        :
                        <div className='infoBox'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('NoResultsFound')}
                                    </span>
                                }
                            </Translation>
                        </div>
                    }

                    {this.state.showAddModal
                        ?
                        <DefaultMessageAddModal
                            modalTitle='AddNewDefaultMessage'
                            closeMethod={this.handleCloseAddModal}
                            saveMethod={this.createDefaultMessage}
                        />
                        :
                        null
                    }

                    {this.state.showDeleteModal
                        ?
                        <DeleteModal
                            itemName={this.state.editedItemName}
                            closeMethod={this.handleCloseDeleteModal}
                            deleteMethod={this.deleteDefaultMessage}
                        />
                        :
                        null
                    }
                </div>
            );
        }
    }
}

export default DefaultMessages;
