import React from 'react';
import './UserInfo.css';
import UserAddModal from '../Users/UserAddModal/UserAddModal';
import {Translation} from "react-i18next";

class UserInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: true,
            items: [],
            error: false,
            chosenUserId: this.props.chosenUserId,
            showEditModal: false
        };

        this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.saveUser = this.saveUser.bind(this);
    }

    componentDidMount() {
        this.setState({
            chosenUserId: this.props.chosenUserId
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.chosenUserId!==this.props.chosenUserId){
            this.setState({
                chosenUserId: this.props.chosenUserId
            });
        }
    }

    handleOpenEditModal() {
        this.setState({
            showEditModal: true
        });
    }

    handleCloseEditModal() {
        this.setState({
            showEditModal: false
        });
    }

    async saveUser(id, email, password, firstName, lastName, initials, company, role) {

        this.setState({
            isLoaded: false
        });

        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('mapperToken')
            },
            body: JSON.stringify({
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                initials: initials,
                company: 'companies/' + company.value,
                companyId: company.value,
                role: role.value.toUpperCase()
            })
        });

        const json = await response.json();

        this.props.fetchUsers(json.id);
        this.handleCloseEditModal()
    };

    render() {
        const { item } = this.props;

        if (!item) {
            return null;
        } else {
            return (
                <div>
                    <div className='userInfoHeader'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('UserInfo')}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className='userInfo'>
                        <div
                            className='userInfoTitleBar'
                            id={item.id}
                        >
                            <div className='userInfoTitle'>
                                {item.fullName}
                            </div>
                            <div className='userInfoActions'>
                                <i className='material-icons actionIcon'
                                   onClick={this.handleOpenEditModal}
                                >
                                    edit
                                </i>
                                <i className='material-icons actionIcon actionIcon--delete'
                                   onClick={this.props.handleOpenDeleteModal}
                                >
                                    delete
                                </i>
                            </div>
                        </div>
                        <div className='userInfoContent'>
                            <div className='userInfoLabel'>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Email')}
                                        </span>
                                    }
                                </Translation>
                            </div>
                            <div className='userInfoText'>
                                {item.email}
                            </div>
                            <div className='userInfoLabel'>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Initials')}
                                        </span>
                                    }
                                </Translation>
                            </div>
                            <div className='userInfoText'>
                                {item.initials}
                            </div>
                            <div className='userInfoLabel'>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Role')}
                                        </span>
                                    }
                                </Translation>
                            </div>
                            <div className='userInfoText'>
                                {item.role.replace(/_/g, " ").replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})}
                            </div>
                        </div>
                    </div>

                    {this.state.showEditModal
                        ?
                        <UserAddModal
                            modalTitle='EditUser'
                            closeMethod={this.handleCloseEditModal}
                            saveMethod={this.saveUser}
                            email={item.email}
                            firstName={item.firstName}
                            lastName={item.lastName}
                            initials={item.initials}
                            company={item.companyId}
                            role={item.role}
                            id={item.id}
                        />
                        :
                        null
                    }

                </div>
            );
        }
    }
}

export default UserInfo;
