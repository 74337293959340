import React from 'react';
import './DefaultMessageAddModal.css';
import {Translation} from "react-i18next";

function ValidationMessage(props) {
    if (!props.valid && props.message) {
        return(
            <Translation>
                {(t, {i18n}) => <div className='error-msg'>{t(`${props.message}`)}</div>}
            </Translation>
        )
    }
    return null;
}

class DefaultMessageAddModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            contentInput: this.props.content, contentValid: !!this.props.content,
            errorMsg: {}
        };

        this.handleAddDefaultMessageClick = this.handleAddDefaultMessageClick.bind(this)
    }

    //Validate functions
    validateContent = () => {
        const {contentInput} = this.state;
        let contentValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(contentInput === undefined || contentInput === '') {
            contentValid = false;
            errorMsg.contentInput = 'PleaseTypeInTheMessage'
        } else if(contentInput.length > 512) {
            contentValid = false;
            errorMsg.contentInput = 'MessageCannotExceed512Characters'
        }
        this.setState({contentValid, errorMsg})
    };

    handleAddDefaultMessageClick = () => {
        if(this.state.contentValid !== true) {
            this.validateContent()
        } else {
            this.props.saveMethod(this.state.contentInput)
        }
    };

    render() {
        return (
            <div className='addDefaultMessageModalBackground'>
                <div className='addDefaultMessageModalWindow'>
                    <div className='addDefaultMessageModalTitle'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t(this.props.modalTitle)}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className='addDefaultMessageModalForm'>
                        <label className='addDefaultMessageModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Content')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.contentValid} message={this.state.errorMsg.contentInput} />
                        <textarea className='addDefaultMessageModalInput'
                               defaultValue={this.props.content}
                               onInput={e => this.setState({contentInput: e.target.value}, this.validateContent)}
                               type="text"
                        />
                        <div className='addDefaultMessageModalButtons'>
                            <button
                                className='addDefaultMessageModalButton addDefaultMessageModalButton--cancel'
                                onClick={this.props.closeMethod}
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Cancel').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                            <button
                                className='addDefaultMessageModalButton addDefaultMessageModalButton--save'
                                onClick={this.handleAddDefaultMessageClick}
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Save').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DefaultMessageAddModal;
