import React from 'react';
import './ProjectsSelectModal.css';
import {Translation} from "react-i18next";

class ProjectsSelectModal extends React.Component {

    render() {
        return (
            <div className='modalBackground'>
                <div className='modalWindow'>
                    <Translation>
                        {(t, {i18n}) =>
                            <div className='modalTitle'>
                                {t('ChooseProject')}:
                            </div>
                        }
                    </Translation>
                    <ul className='projectsSelectList' onClick={this.props.handleChange}>
                        {this.props.projects.map((project) => (
                            <li
                                value={project.id}
                                key={project.id}
                            >
                                {project.name}
                            </li>
                        ))}
                    </ul>
                    <div className='modalButtons'>
                        <Translation>
                            {(t, {i18n}) =>
                                <button
                                    className='modalButton'
                                    onClick={this.props.closeMethod}
                                >
                                    {t('Cancel').toUpperCase()}
                                </button>
                            }
                        </Translation>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectsSelectModal;
