import React from 'react';
import '../BuildingsDetails/BuildingsDetails.css';
import './Layers.css';
import { getCompanyId } from '../../helpers/helpers';

import List from '../common/List/List';
import Spinner from '../common/Spinner/Spinner';
import LayerInfo from '../LayerInfo/LayerInfo';
import LayerAddModal from './LayerAddModal/LayerAddModal';
import DeleteModal from '../common/DeleteModal/DeleteModal';
import {Translation} from "react-i18next";

class Layers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: true,
            items: [],
            layerParts: [],
            goToPage: 1,
            itemsPerPage: 10,
            chosenLayerId: null,
            showAddModal: false,
            editedItemName: '',
            showDeleteModal: false
        };

        this.fetchLayers = this.fetchLayers.bind(this);
        this.fetchLayerParts = this.fetchLayerParts.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.createLayer = this.createLayer.bind(this);
        this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.deleteLayer = this.deleteLayer.bind(this);
    }

    componentDidMount() {
        this.fetchLayers();
    }

    fetchLayers(id) {

        this.setState({
            isLoaded: false
        });

        fetch(`${process.env.REACT_APP_API_URL}/layers?size=1000`, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(res => res.json())
            .then(
                response => {

                    if (response._embedded.layers.length !== 0) {

                        const index = response._embedded.layers.map(function(e) {return e.id; }).indexOf(id);
                        const page = index === -1 ? 1 : Math.floor(index / this.state.itemsPerPage) + 1;

                        !(index === -1)  ?
                            this.setState({
                                chosenLayerId: id,
                                chosenLayer: response._embedded.layers.find(x => x.id === id),
                                chosenLayerName: response._embedded.layers.find(x => x.id === id).name,
                                goToPage: page,
                                isLoaded: true,
                                items: response._embedded.layers,
                                error: false
                            }, () =>
                                this.fetchLayerParts()
                            )
                            :
                            this.setState({
                                chosenLayerId: response._embedded.layers[0].id,
                                chosenLayer: response._embedded.layers[0],
                                chosenLayerName: response._embedded.layers[0].name,
                                goToPage: page,
                                isLoaded: true,
                                items: response._embedded.layers,
                                error: false
                            }, () =>
                                    this.fetchLayerParts()
                            )
                    } else {
                        this.setState({
                            isLoaded: true
                        });
                    }
                }
            )
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                    items: [],
                    error
                });
            })

    }

    fetchLayerParts() {

        if(this.state.chosenLayerId) {

            fetch(`${process.env.REACT_APP_API_URL}/layerParts?size=1000`, {
                method: 'GET',
                headers: {
                    'Authorization': sessionStorage.getItem('mapperToken')
                }
            })
                .then(res => res.json())
                .then(
                    response => {
                        this.setState({
                            layerParts: response._embedded.layerParts
                        });
                    }
                )
        }
    }

    handleOpenAddModal() {
        this.setState({
            showAddModal: true
        });
    }

    handleCloseAddModal() {
        this.setState({
            showAddModal: false
        });
    }

    async createLayer(id, name, color, prefixes, transparent, visible) {

        this.setState({
            isLoaded: false
        });

        const response = await fetch(`${process.env.REACT_APP_API_URL}/layers/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('mapperToken')
            },
            body: JSON.stringify({
                name: name,
                color: color,
                companyId: getCompanyId(),
                defaultTransparent: transparent,
                defaultVisible: visible
            })
        });

        const json = await response.json();

        prefixes.forEach((prefix) => {
            fetch(`${process.env.REACT_APP_API_URL}/layerParts/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionStorage.getItem('mapperToken')
                },
                body: JSON.stringify({
                    layerId: json.id,
                    layer: 'layers/' + json.id,
                    prefix: prefix
                })
            })
        });

        this.handleCloseAddModal();
        this.fetchLayers(json.id);
    };

    handleOpenDeleteModal() {

        this.setState({
            showDeleteModal: true
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    deleteLayer() {

        const deleteItemId = this.state.chosenLayerId;
        const apiUrl = `${process.env.REACT_APP_API_URL}/layers/` + deleteItemId;

        fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(this.fetchLayers)
            .then(this.handleCloseDeleteModal)
    }

    handleListItemClick(e) {
        this.setState({
            chosenLayerId: Number(e.target.parentElement.id),
            chosenLayerName: this.state.items.find(x => x.id === this.state.chosenLayerId).name
        });
    }

    render() {

        const { error, isLoaded, items, layerParts } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <Spinner/>;
        } else {
            return (
                <div className='page'>
                    <div className='pageHeader'>
                        <div>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Layers')}
                                    </span>
                                }
                            </Translation>
                        </div>
                        <div className='buttonsWrapper'>
                            <i className='material-icons createIcon'
                               onClick={this.handleOpenAddModal}
                            >
                                add
                            </i>
                        </div>
                    </div>
                    {items.length ?
                        <div className='pageContent'>
                            <div className='pageContentColumn1-2'>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <List
                                            items={items}
                                            filter={false}
                                            columnsTitles={[t('Name'), t('Color')]}
                                            columnsSources={['name', 'color']}
                                            edit={false}
                                            download={false}
                                            delete={false}
                                            currentPage={this.state.goToPage}
                                            itemsPerPage={this.state.itemsPerPage}
                                            searchValue={this.props.searchValue}
                                            handleListItemClick={this.handleListItemClick}
                                            chosenId={this.state.chosenLayerId}
                                            spreadList
                                            layerColorColumn='2'
                                        />
                                    }
                                </Translation>
                            </div>
                            <div className='pageContentColumn2-2'>
                                <LayerInfo
                                    item={items.find(x => x.id === this.state.chosenLayerId)}
                                    layerParts={layerParts}
                                    handleOpenDeleteModal={this.handleOpenDeleteModal}
                                    fetchLayers={this.fetchLayers}
                                />
                            </div>
                        </div>
                        :

                        <div className='infoBox'>
                            <Translation>
                                {(t, {i18n}) =>
                                        <span>
                                            {t('NoResultsFound')}
                                        </span>
                                }
                            </Translation>
                        </div>
                    }
                    {this.state.showAddModal
                        ?
                        <LayerAddModal
                            modalTitle='AddNewLayer'
                            closeMethod={this.handleCloseAddModal}
                            saveMethod={this.createLayer}
                            prefixes={[]}
                        />
                        :
                        null
                    }

                    {this.state.showDeleteModal
                        ?
                        <DeleteModal
                            itemName={items.find(x => x.id === this.state.chosenLayerId).name + ' layer'}
                            closeMethod={this.handleCloseDeleteModal}
                            deleteMethod={this.deleteLayer}
                        />
                        :
                        null
                    }
                </div>
            );
        }
    }
}

export default Layers;
