import React from 'react';
import './Header.css';

import AppBarrIconChecker from '../../assets/icons/u129.png'
import AppBarrIconMapper from '../../assets/icons/u127.png'
import AppBarrIcon3 from '../../assets/icons/u128.png'

import ProjectsSelect from './ProjectsSelect/ProjectsSelect';
import LanguageSelect from './LanguageSelect/LanguageSelect';
import {getCompany} from "../../helpers/helpers";

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sideBarLabelsShow: false
        };

        this.handleHamburgerClick = this.handleHamburgerClick.bind(this);
    }

    handleHamburgerClick() {

        this.setState({
            sideBarLabelsShow: !this.state.sideBarLabelsShow
        }, () => this.props.showSideBarLabels());
    }

    render() {

        const { changeProject } = this.props;
        const { sideBarLabelsShow } = this.state;

        return (
            <header className='appHeader'>
                <div className='appHeader--left'>
                    <div className='hamburger'
                        onClick={this.handleHamburgerClick}
                    >
                        <div className={sideBarLabelsShow ? 'hamburgerBars--rotated' : 'hamburgerBars'}>
                            <div className='hamburgerBar'/>
                            <div className='hamburgerBar'/>
                            <div className='hamburgerBar'/>
                        </div>
                    </div>
                    <h1 className='appTitle'>
                        {getCompany()}
                    </h1>
                </div>
                <div className='appHeader--center'>
                    <a href={process.env.REACT_APP_CHECKER_URL}>
                        <img src={AppBarrIconChecker} alt='AppBar icon' className='appBarIcon'/>
                    </a>
                    <img src={AppBarrIconMapper} alt='AppBar icon' className='appBarIcon appBarIcon--active'/>
                    <img src={AppBarrIcon3} alt='AppBar icon' className='appBarIcon appBarIcon--disabled'/>
                </div>
                <div className='appHeader--right'>
                    <LanguageSelect

                    />
                    <ProjectsSelect
                        selectedProject={this.props.selectedProject}
                        projects={this.props.projects}
                        changeProject={changeProject}
                    />
                </div>
            </header>
        );
    }
}

export default Header;
