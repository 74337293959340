import React from 'react';
import './AlertModal.css';
import {Translation} from "react-i18next";

class AlertModal extends React.Component {

    render() {
        return (
            <div className='alertModalBackground'>
                <div className='alertModalWindow'>
                    <div className='alertModalTitle'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t(this.props.alert)}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className='alertModalButtons'>
                        <button
                            className='alertModalButton'
                            onClick={this.props.closeMethod}
                        >
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Ok').toUpperCase()}
                                    </span>
                                }
                            </Translation>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default AlertModal;
