import React from 'react';
import {withRouter} from 'react-router-dom'
import './List.css';

class ListItem extends React.Component {

    render() {

        const {
            id, 
            chosenId,
            item,
            columnsSources,
            edit,
            editMethod,
            download,
            downloadUrl,
            deleteMethod,
            dateColumn,
            linkColumn,
            linkText,
            linkUnavailableText,
            referenceColumn,
            referenceItems,
            layerColorColumn,
            imgColumn,
            booleanColumns,
            roleColumns,
            statusColumn,
            statusTotal,
            handleOpenMessageBoardLightboxModal,
            handleListItemClick
        } = this.props;

        const columnsNumber = (edit || download || this.props.delete)
            ? columnsSources.length + 1
            : columnsSources.length;

        const columnWidth = (100 / columnsNumber) + '%';

        const statusBarDone = (item[columnsSources[Number(statusColumn) - 1]]) / (item[statusTotal]);

        const checkFieldType = (index) => {
            if(index === Number(dateColumn) - 1) {
                return item[columnsSources[index]].split('T').shift()
            }

            if(index === Number(linkColumn) - 1 && item[columnsSources[index]]) {
                return <a href={item[columnsSources[index]]}>{linkText}</a>
            }

            if(index === Number(linkColumn) - 1 && !item[columnsSources[index]]) {
                return linkUnavailableText
            }

            if(index === Number(referenceColumn) - 1) {
                return referenceItems.find(x => x.id === item[columnsSources[index]]).name
            }

            if(index === Number(layerColorColumn) - 1) {
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div
                            style={{
                                background: item[columnsSources[index]],
                                width: '25px',
                                height: '25px',
                                marginRight: '15px'
                            }}>
                        </div>
                        {item[columnsSources[index]]}
                    </div>
                )
            }

            if(index === Number(imgColumn) - 1 && item[columnsSources[index]]) {

                if(item[columnsSources[index]].length) {
                    return (
                        <div>
                            <img
                                className='tableImg'
                                onClick={handleOpenMessageBoardLightboxModal}
                                src={item[columnsSources[index]][0]}
                                alt='img'
                            />
                            <span>{` (${item[columnsSources[index]].length})`}</span>
                        </div>
                    )
                } else {
                    return <div>-</div>
                }
            }

            if(booleanColumns && booleanColumns.includes(index + 1) && item[columnsSources[index]]) {
                return <i className='material-icons' style={{color: '#0047AB'}}>done</i>
            }

            if(booleanColumns && booleanColumns.includes(index + 1) && item[columnsSources[index]] === false) {
                return <i className='material-icons' style={{color: '#FF0000'}}>clear</i>
            }

            if(roleColumns && roleColumns.includes(index + 1) && item[columnsSources[index]]) {
                return item[columnsSources[index]].replace(/_/g, ' ').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
            }

            if(index === Number(statusColumn) - 1) {
                return (
                    <div className='statusHolder'>
                        <div className='statusCount'>
                            {item[columnsSources[index]]} / {item[statusTotal]}
                        </div>
                        <div className='statusBar'>
                            <div className='statusBarDone' style={{width: statusBarDone * 100 + '%'}}>
                                {statusBarDone === 1 ? 'Done' : ''}
                            </div>
                            <div className='statusBarNotDone' style={{width: (1 - statusBarDone) * 100 + '%'}}/>
                        </div>
                    </div>
                )
            }

            return item[columnsSources[index]]
        };

        return (
            <tr className={Number(id) === Number(chosenId) ? 'tableRow tableRow--active' : 'tableRow'} id={id}>

                {columnsSources.map((col, index) => (
                    <td
                        key={index}
                        className={'tableField tableField--column' + (index + 1)}
                        onClick={handleListItemClick}
                        style={{width: columnWidth}}
                    >
                        {checkFieldType(index)}
                    </td>
                ))}
                {(edit || download || this.props.delete) ?
                    <td className='tableField tableField--actions'>
                        {(edit)
                            ?
                            <i className='material-icons actionIcon'
                               onClick={editMethod}
                            >
                                edit
                            </i>
                            :
                            null
                        }
                        {(download)
                            ?
                            <form method='get' action={item[downloadUrl] ? item[downloadUrl] : null}>
                                {item[downloadUrl] ?
                                    <button className='downloadButton' type='submit'>
                                        <i className='material-icons actionIcon'>
                                            get_app
                                        </i>
                                    </button>
                                    : null
                                }
                            </form>
                            :
                            null
                        }
                        {(this.props.delete)
                            ?
                            <i className='material-icons actionIcon'
                               onClick={deleteMethod}
                            >
                                delete
                            </i>
                            :
                            null
                        }
                    </td>
                    : null
                }
            </tr>
        );
    }
}

export default withRouter(ListItem);
