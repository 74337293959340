import React from 'react';
import './CompanyInfo.css';

import CompanyAddModal from '../Companies/CompanyAddModal/CompanyAddModal';
import {Translation} from "react-i18next";

class CompanyInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: true,
            items: [],
            error: false,
            chosenCompanyId: this.props.chosenCompanyId,
            showEditModal: false
        };

        this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.saveCompany = this.saveCompany.bind(this);
    }

    componentDidMount() {
        this.setState({
            chosenCompanyId: this.props.chosenCompanyId
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.chosenCompanyId!==this.props.chosenCompanyId){
            this.setState({
                chosenCompanyId: this.props.chosenCompanyId
            });
        }
    }

    handleOpenEditModal() {
        this.setState({
            showEditModal: true
        });
    }

    handleCloseEditModal() {
        this.setState({
            showEditModal: false
        });
    }

    async saveCompany(id, name, arrebar, raven, image) {

        this.setState({
            isLoaded: false
        });

        const response = await fetch(`${process.env.REACT_APP_API_URL}/companies/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('mapperToken')
            },
            body: JSON.stringify({
                name: name,
                arrebarEnabled: arrebar,
                ravenEnabled: raven
            })
        });

        const json = await response.json();

        if(image) {
            this.addPhoto(image, json.id)
        } else {
            this.props.fetchCompanies(json.id);
            this.handleCloseEditModal()
        }
    };

    addPhoto = (image, jsonId) => {
        if(image) {
            const formData = new FormData();
            formData.append('file', image, image.name);

            fetch(`${process.env.REACT_APP_API_URL}/customCompany/${jsonId}/logo_upload`, {
                method: 'PUT',
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                },
                body: formData
            })
                .then(() => this.props.fetchCompanies(jsonId))
                .then(this.handleCloseEditModal)
        }
    }

    render() {
        const { item } = this.props;

        if (!item) {
            return null;
        } else {
            return (
                <div>
                    <div className='companyInfoHeader'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('CompanyInfo')}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className='companyInfo'>
                        <div
                            className='companyInfoTitleBar'
                            id={item.id}
                        >
                            <div className='companyInfoTitle'>
                                {item.name}
                            </div>
                            <div className='companyInfoActions'>
                                <i className='material-icons actionIcon'
                                   onClick={this.handleOpenEditModal}
                                >
                                    edit
                                </i>
                                <i className='material-icons actionIcon actionIcon--delete'
                                   onClick={this.props.handleOpenDeleteModal}
                                >
                                    delete
                                </i>
                            </div>
                        </div>
                        <div className='companyInfoContent'>
                            <div className="companyInfoContent--left">
                                <div className='companyInfoLabel'>
                                    <Translation>
                                        {(t, {i18n}) =>
                                            <span>
                                                {t('CompanyEmail')}
                                            </span>
                                        }
                                    </Translation>
                                </div>
                                <div className="companyEmail">loremipsum@gmail.com</div>
                                <div className='companyInfoLabel'>
                                    <Translation>
                                        {(t, {i18n}) =>
                                            <span>
                                                {t('ArrebarEnabled')}
                                            </span>
                                        }
                                    </Translation>
                                </div>
                                <div className='companyInfoBoolean'>
                                    {item.arrebarEnabled ?
                                        <i className='material-icons iconTrue'>done</i>
                                        :
                                        <i className='material-icons iconFalse'>clear</i>
                                    }
                                </div>
                                <div className='companyInfoLabel--next'>
                                    <Translation>
                                        {(t, {i18n}) =>
                                            <span>
                                                {t('RavenEnabled')}
                                            </span>
                                        }
                                    </Translation>
                                </div>
                                <div className='companyInfoBoolean'>
                                    {item.ravenEnabled ?
                                        <i className='material-icons iconTrue'>done</i>
                                        :
                                        <i className='material-icons iconFalse'>clear</i>
                                    }
                                </div>
                            </div>
                            <div className="companyInfoContent--right">
                                <div className="companyLogoDiv">
                                    {
                                        this.props.item.logoUrl
                                            ? <img className='companyLogo' src={this.props.item.logoUrl} alt='company logo' />
                                            : null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showEditModal
                        ?
                        <CompanyAddModal
                            modalTitle='EditCompany'
                            closeMethod={this.handleCloseEditModal}
                            saveMethod={this.saveCompany}
                            name={item.name}
                            arrebar={item.arrebarEnabled}
                            raven={item.ravenEnabled}
                            id={this.props.chosenCompanyId}
                            email={item.sendFromEmail}
                            isVerified={item.sendFromConfirmed}
                        />
                        :
                        null
                    }

                </div>
            );
        }
    }
}

export default CompanyInfo;
