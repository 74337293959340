import React from 'react';
import './LayerAddModal.css';
import { SketchPicker } from 'react-color';
import Switch from "react-switch";

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import {Translation} from "react-i18next";

function ValidationMessage(props) {
    if (!props.valid && props.message) {
        return(
            <Translation>
                {(t, {i18n}) => <div className='error-msg'>{t(`${props.message}`)}</div>}
            </Translation>
        )
    }
    return null;
}

class LayerAddModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            nameInput: this.props.name, nameValid: !!this.props.name,
            colorInput: this.props.color, colorValid: !!this.props.color,
            showColorPicker: false,
            dropdownVisible: false,
            prefixes: this.props.prefixes,
            transparent: this.props.transparent ? this.props.transparent : false,
            visible: this.props.visible ? this.props.visible : false,
            errorMsg: {}
        };
    }

    showColorPicker = () => {
        this.setState({
            showColorPicker: true
        });
    };

    hideColorPicker = () => {
        this.setState({
            showColorPicker: false
        });
    };

    handleColorChange = (color) => {
        this.setState({
            colorInput: color.hex
        }, this.validateColor);
    };


    handlePrefixesAdd = (prefixes) => {
        this.setState({
            prefixes
        })
    };

    handleTransparentChange = () => {
        this.setState({
            transparent: !this.state.transparent
        })
    };

    handleVisibleChange = () => {
        this.setState({
            visible: !this.state.visible
        })
    };
    
    //Validate functions:
    validateName = () => {
        const {nameInput} = this.state;
        let nameValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(nameInput === undefined || nameInput === '') {
            nameValid = false;
            errorMsg.nameInput = 'PleaseTypeInTheName'
        } else if(nameInput.length < 3) {
            nameValid = false;
            errorMsg.nameInput = 'NameNeedsToContainAtLeast3Characters'
        } else if(nameInput.length > 30) {
            nameValid = false;
            errorMsg.nameInput = 'NameCannotBeLongerThan30Characters'
        }
        this.setState({nameValid, errorMsg})
    };

    validateColor = () => {
        const {colorInput} = this.state;
        let colorValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(colorInput === undefined || colorInput === '') {
            colorValid = false;
            errorMsg.colorInput = 'PleaseSelectAColor'
        }
        this.setState({colorValid, errorMsg})
    };

    handleAddEditLayerClick = () => {
        if(this.state.nameValid !== true) {
            this.validateName()
        } else if(this.state.colorValid !== true) {
            this.validateColor()
        } else {
            this.props.saveMethod(
                this.props.id,
                this.state.nameInput,
                this.state.colorInput,
                this.state.prefixes,
                this.state.transparent,
                this.state.visible
            )
        }
    };

    render() {

        return (
            <div className='addLayerModalBackground'>
                <div className='addLayerModalWindow'>
                    <div className='addLayerModalTitle'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t(this.props.modalTitle)}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className='addLayerModalForm'>
                        <label className='addLayerModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Name')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.nameInput} />
                        <input className='addLayerModalInput addLayerModalInput--layerName'
                               defaultValue={this.props.name}
                               onInput={e => this.setState({nameInput: e.target.value}, this.validateName)}
                               type="text"
                        />
                        <label className='addLayerModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Color')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.colorValid} message={this.state.errorMsg.colorInput} />
                        <div
                            className='addLayerModalInput addLayerColorSelect'
                            onClick={!this.state.showColorPicker ? this.showColorPicker : this.hideColorPicker}
                        >
                            <div className='addLayerColorDisplay'>
                                <div
                                    className='addLayerColorBox'
                                    style={{background: this.state.colorInput}}
                                />
                                <div className='addLayerColorHex'>
                                    {this.state.colorInput}
                                </div>
                            </div>
                            {!this.state.showColorPicker ?
                                    <i className='material-icons dropdownIcon'>
                                        keyboard_arrow_down
                                    </i>
                                :
                                    <i className='material-icons dropdownIcon'>
                                        keyboard_arrow_up
                                    </i>
                            }
                        </div>
                        <div onMouseLeave={this.hideColorPicker}>
                            <SketchPicker
                                className={this.state.showColorPicker ? 'colorPicker colorPicker--active' : 'colorPicker'}
                                onChangeComplete={this.handleColorChange}
                                color={this.state.colorInput}
                                disableAlpha
                            />
                        </div>

                        <label className='addLayerModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('PartsByPrefixes')}
                                    </span>
                                }
                            </Translation>
                            <br/>
                            <Translation>
                                {(t, {i18n}) =>
                                    <i>
                                        {t('PartsByPrefixesInstruction')}
                                    </i>
                                }
                            </Translation>
                        </label>

                        <Translation>
                            {(t, {i18n}) =>
                                <TagsInput
                                    value={this.state.prefixes}
                                    onChange={this.handlePrefixesAdd}
                                    inputProps={{className: 'react-tagsinput-input', placeholder: t('AddPart')}}
                                />
                            }
                        </Translation>

                        <div className='switchHolder'>
                            <Switch
                                onChange={this.handleTransparentChange}
                                checked={this.state.transparent}
                                onColor='#0047AB'
                                offColor='#999999'
                            />
                            <Translation>
                                {(t, {i18n}) =>
                                    <div className='addLayerModalInputLabel addLayerModalSwitchInputLabel'>
                                        {t('Transparent')}
                                    </div>
                                }
                            </Translation>
                        </div>



                        <div className='switchHolder'>
                            <Switch
                                onChange={this.handleVisibleChange}
                                checked={this.state.visible}
                                onColor='#0047AB'
                                offColor='#999999'
                            />
                            <Translation>
                                {(t, {i18n}) =>
                                    <div className='addLayerModalInputLabel addLayerModalSwitchInputLabel'>
                                        {t('Visible')}
                                    </div>
                                }
                            </Translation>
                        </div>

                        <div className='addLayerModalButtons'>
                            <button
                                className='addLayerModalButton addLayerModalButton--cancel'
                                onClick={this.props.closeMethod}
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Cancel').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                            <button
                                className='addLayerModalButton addLayerModalButton--save'
                                onClick={this.handleAddEditLayerClick}
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Save').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LayerAddModal;
