import React from 'react';
import './ProjectsSelect.css';
import ProjectsSelectModal from "./ProjectsSelectModal/ProjectsSelectModal";
import {Translation} from "react-i18next";

class ProjectsSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProject: Number(sessionStorage.getItem('currentProjectId')),
            showDialog: false,
            isLoaded: false
        };

    }

    componentDidMount() {
        this.setState({
            selectedProject: Number(sessionStorage.getItem('currentProjectId'))
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.projects !== this.props.projects) {
            this.setState({
                selectedProject: Number(sessionStorage.getItem('currentProjectId'))
            })
        }
    }

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleChange = e => {
        const currentProjectId = e.target.value;

        sessionStorage.setItem('currentProjectId', currentProjectId);

        this.setState({
            selectedProject: currentProjectId
        });

        this.props.changeProject(currentProjectId);

        this.handleCloseClick();
    };

    render() {

        const {projects} = this.props;
        const {selectedProject, showDialog} = this.state;

        const projectIndex = projects.map(function (e) {
            return e.id;
        }).indexOf(selectedProject);

        return (
            <div className='changeProjectButtonHolder'>

                <div className='changeProjectButton' onClick={this.handleClick}>
                    <Translation>
                        {(t, {i18n}) =>
                            <div className='changeProjectButtonLabel'>
                                {projects[projectIndex] ? projects[projectIndex].name : t('ChooseProject')}
                            </div>
                        }
                    </Translation>
                </div>

                {showDialog ?
                    <ProjectsSelectModal
                        projects={projects}
                        handleChange={this.handleChange}
                        closeMethod={this.handleCloseClick}
                    />
                    :
                    null
                }

            </div>
        )
    }
}

export default ProjectsSelect;
