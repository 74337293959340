import React from 'react';
import '../LoginPage/LoginPage.css';
import ButtonBubbleSpinner from '../common/ButtonBubblSpinner/ButtonBubbleSpinner';
import { NavLink } from 'react-router-dom';
import {Translation} from 'react-i18next';

class SetPasswordPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            passwordInput: '',
            message: {text: '', type: ''},
            pending: false
        };
    }

    componentWillMount = () => {
        this.setState({message: {text: '', type: 'INFO'}});
    }

    handleSetClick = () => {
        const { passwordInput } = this.state;
        const token = this.props.history.location.search.split('token=').pop();
        this.setPassword(passwordInput, token);
    };

    actionOnEnter = e => {

        if (e.keyCode === 13) {
            this.handleSetClick();
        }

    };

    setPassword = (password, token) => {
        this.setState({pending: true});

        fetch(`${process.env.REACT_APP_API_URL}/custom/resetPassword/complete`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                password,
                token
            })
        })
            .then(res => {
                if (res.status === 200) {
                    this.setState({message: {text: 'Password has been set', type: 'INFO'}, pending: false});
                }
                else
                    throw new Error();
            })
            .catch((err) => {
                this.setState({message: {text: 'Wrong request data', type: 'ERROR'}, pending: false});
            });
    };

    render() {
        const { message, pending } = this.state;

        return (
            <div className='loginPage'>
                <div
                    className='loginWindow'
                    onKeyDown={this.actionOnEnter}
                >
                    <div className='loginIconHolder'>
                        <i className='material-icons loginIcon'>
                            vpn_key
                        </i>
                    </div>
                    <div className="resetPasswordTitle">
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('Set new password')}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className={message.type === "INFO" ? 'requestSuccessMessage' : 'requestFailedMessage'}>
                        {message.text
                            ? <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t(message.text)}
                                    </span>
                                }
                            </Translation>
                            : null
                        }
                    </div>
                    <label className='loginWindowInputLabel'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('Password')}*
                                </span>
                            }
                        </Translation>
                    </label>
                    <input
                        className='loginWindowInput'
                        id="password"
                        onInput={(e) => this.setState({ passwordInput: e.target.value })}
                        type="password"
                    />
                    <div className='loginButtonHolder'>
                        <button
                            className='loginButton'
                            type="button"
                            onClick={this.handleSetClick}
                            disabled={!this.state.passwordInput}
                        >
                            {pending
                                ? <ButtonBubbleSpinner />
                                : <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Set').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            }
                        </button>
                        <div className="resetPassword">
                            <NavLink
                                className='registerText'
                                to='/login'
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                        {t('Go back to login page').toUpperCase()}
                                    </span>
                                    }
                                </Translation>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetPasswordPage;