import React from 'react';
import '../BuildingsDetails/BuildingsDetails.css';
import "react-tabs/style/react-tabs.css";

import List from '../common/List/List';
import Spinner from '../common/Spinner/Spinner';
import {Tabs, Tab, TabList, TabPanel} from 'react-tabs';

import Rooms from '../Rooms/Rooms';
import FloorPlanInfo from "../FloorPlanInfo/FloorPlanInfo";
import {Translation} from "react-i18next";

class Floors extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: true,
            items: [],
            goToPage: 1,
            itemsPerPage: 10,
            chosenFloorId: null
        };

        this.fetchFloors = this.fetchFloors.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
    }

    componentDidMount() {
        this.fetchFloors()
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedProject!==this.props.selectedProject){
            this.fetchFloors();
        }
    }

    fetchFloors() {

        if(this.props.selectedProject) {
            this.setState({
                isLoaded: false
            });

            fetch(`${process.env.REACT_APP_API_URL}/mapperFloors?buildingId=${this.props.selectedProject}`, {
                method: 'GET',
                headers: {
                    'Authorization': sessionStorage.getItem('mapperToken')
                }
            })
                .then(res => res.json())
                .then(
                    response => {
                        this.setState({
                                isLoaded: true,
                                items: response._embedded.floors,
                                error: false
                            }, () => {
                                if (this.state.items.length !== 0) {
                                    this.setState({
                                        chosenFloorId: this.state.items[0].id
                                    })
                                }
                            }
                        );
                    }
                )
                .catch((error) => {
                    this.setState({
                        isLoaded: true,
                        items: [],
                        error
                    });
                })
        }
    }

    handleListItemClick(e) {
        if(e.target.tagName.toLowerCase() !== 'a') {
            this.setState({
                chosenFloorId: Number(e.target.parentElement.id)
            });
        }
    }

    render() {
        const { error, isLoaded, items, chosenFloorId } = this.state;
        const { selectedProject, projects } = this.props;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded || !projects.length) {
            return <Spinner/>;
        } else if (!items.length) {
            return <div className='infoBox'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('NoResultsFound')}
                                </span>
                            }
                        </Translation>
                    </div>;
        } else {
            return (
                <div className='page'>
                    <div className='pageHeader'>
                        <div>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Floors')}
                                    </span>
                                }
                            </Translation>
                        </div>
                    </div>
                    <div className='pageContent'>
                        <div className='pageContentColumn1-2'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <List
                                        items={items}
                                        filter={true}
                                        filterValue={selectedProject}
                                        filterField='buildingId'
                                        columnsTitles={[t('Name'), t('FloorPlan'), t('Building'), t('BundlingStatus')]}
                                        columnsSources={['name', 'mainFloorPlanURL', 'buildingId', 'roomsCount']}
                                        linkColumn='2'
                                        linkText={t('DownloadPdf')}
                                        linkUnavailableText={t('FloorPlanUnavailable')}
                                        referenceColumn='3'
                                        referenceItems={projects}
                                        statusColumn='4'
                                        statusTotal='roomsTotal'
                                        edit={false}
                                        download={false}
                                        delete={false}
                                        currentPage={this.state.goToPage}
                                        itemsPerPage={this.state.itemsPerPage}
                                        searchValue={this.props.searchValue}
                                        handleListItemClick={this.handleListItemClick}
                                        chosenId={this.state.chosenFloorId}
                                    />
                                }
                            </Translation>
                        </div>
                        <div className='pageContentColumn2-2'>
                            <Tabs>
                                <TabList>
                                    <Tab>
                                        <Translation>
                                            {(t, {i18n}) =>
                                                <span>
                                                    {t('Rooms')}
                                                </span>
                                            }
                                        </Translation>
                                    </Tab>
                                    <Tab>
                                        <Translation>
                                            {(t, {i18n}) =>
                                                <span>
                                                    {t('FloorPlanInfo')}
                                                </span>
                                            }
                                        </Translation>
                                    </Tab>
                                </TabList>

                                <TabPanel className='tabPanel'>
                                    <Rooms
                                        projects={projects}
                                        chosenFloorId={chosenFloorId}
                                        selectedProject={selectedProject}
                                        searchValue={this.props.searchValue}
                                    />
                                </TabPanel>
                                <TabPanel className='tabPanel'>
                                    {projects && selectedProject ?
                                        <FloorPlanInfo
                                            floors={items}
                                            projects={projects}
                                            selectedProject={selectedProject}
                                            chosenFloorId={chosenFloorId}
                                            fetchFloors={this.fetchFloors}
                                        />
                                        : null
                                    }
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Floors;
