import React from 'react';
import './BuildingInfo.css';
import {Translation} from "react-i18next";

class BuildingInfo extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            chosenBuildingId: null
        };
    }

    componentDidMount() {
        this.setState({
            chosenBuildingId: this.props.selectedProject
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedProject!==this.props.selectedProject){
            this.setState({
                chosenBuildingId: this.props.selectedProject
            })
        }
    }

    render() {
        const { items, selectedProject, chosenBuildingId } = this.props;

        const currentBuilding = chosenBuildingId ? (items.filter(item => item.id === chosenBuildingId))[0] : (items.filter(item => item.id === selectedProject))[0];

        if (!currentBuilding) {
            return null;
        } else {
            return (
                <div className='buildingInfo'>
                    <div
                        className='buildingInfoTitleBar'
                        id={currentBuilding.id}
                    >
                        <div className='buildingInfoTitle'>
                            {currentBuilding.name}
                        </div>
                        <div className='buildingInfoActions'>
                            <i className='material-icons actionIcon'
                               onClick={this.props.editMethod}
                            >
                                edit
                            </i>
                            <i className='material-icons actionIcon actionIcon--delete'
                               onClick={this.props.deleteMethod}
                            >
                                delete
                            </i>
                        </div>
                    </div>
                    <div className='buildingInfoContent'>
                        <div className='buildingImageWrapper'>
                            {currentBuilding.imageUrl ?
                                <img
                                    src={currentBuilding.imageUrl}
                                    alt='Building'
                                />
                                :
                                <Translation>
                                    {(t, {i18n}) =>
                                        <div className='placeholderImage'>
                                            {t('ImageUnavailable')}
                                        </div>
                                    }
                                </Translation>
                            }
                        </div>
                        <div className='buildingInfoSectionTop'>
                            <div className='buildingInfoLabel'>
                                {currentBuilding.location}
                            </div>
                            <div className='buildingInfoLabel buildingInfoLabel--date'>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Created')}:
                                        </span>
                                    }
                                </Translation>
                                <span>{' ' + currentBuilding.createdAt.split('T').shift()}</span>
                            </div>
                        </div>
                        <div className='buildingInfoSectionBottom'>
                            <div className='buildingInfoLabel'>
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Description')}
                                        </span>
                                    }
                                </Translation>
                            </div>
                            <div className='buildingInfoText'>
                                {currentBuilding.description ? currentBuilding.description : 'Description unavailable'}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default BuildingInfo;
