import React from "react";
import './UploadBar.css';

class UploadBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            highlight: false,
            files: []
        };

        this.fileInputRef = React.createRef();

        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.clearFilesArray = this.clearFilesArray.bind(this);
    }

    openFileDialog() {
        this.fileInputRef.current.click();
    }

    onFilesAdded(e) {
        const files = e.target.files;
        const filesArray = this.fileListToArray(files);
        this.setState({
            files: filesArray
        },() => this.state.files.length !== 0
            ? this.props.sendMethod(this.state.files)
            : null
        );
    }

    fileListToArray(list) {
        let filesArray = this.state.files;
        for (let i = 0; i < list.length; i++) {
            filesArray.push(list.item(i))
        }
        return filesArray;
    }

    onDragOver(e) {
        e.preventDefault();

        if(!this.props.inactive) {
            this.setState({
                highlight: true
            });
        }
    }

    onDragLeave() {
        if(!this.props.inactive) {
            this.setState({
                highlight: false
            });
        }
    }

    onDrop(e) {
        e.preventDefault();

        if(!this.props.inactive) {
            const files = e.dataTransfer.files;
            const filesArray = this.fileListToArray(files);
            this.setState({
                    files: filesArray,
                    highlight: false
                }, () => this.state.files.length !== 0
                ? this.props.sendMethod(this.state.files, this.clearFilesArray)
                : null
            );
        }
    }

    clearFilesArray() {
        this.setState({
            files: []
        })
    }

    render() {
        return (
            <div
                className={`dropzone ${this.state.highlight ? "highlight" : ""} ${this.props.inactive ? "inactive" : ""}`}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                onClick={this.props.inactive ? null : this.openFileDialog}
            >
                {this.props.uploadBarText}
                <input
                    ref={this.fileInputRef}
                    className="fileInput"
                    type="file"
                    multiple
                    onChange={this.onFilesAdded}
                />
            </div>
        );
    }
}

export default UploadBar;
