import React, {Component} from 'react';
import './Users.css';
import {getCompanyId, isSuperAdmin} from '../../helpers/helpers';

import Spinner from '../common/Spinner/Spinner';
import List from '../common/List/List';
import UserInfo from '../UserInfo/UserInfo';
import UserAddModal from './UserAddModal/UserAddModal';
import DeleteModal from '../common/DeleteModal/DeleteModal';
import {Translation} from "react-i18next";

class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: true,
            items: [],
            goToPage: 1,
            itemsPerPage: 10,
            chosenUserId: null,
            showAddModal: false,
            editedItemName: '',
            showDeleteModal: false
        };

        this.fetchUsers = this.fetchUsers.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.createUser = this.createUser.bind(this);
        this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers(id) {

        this.setState({
            isLoaded: false
        });

        const apiUrl = isSuperAdmin() ? `${process.env.REACT_APP_API_URL}/users?size=1000` : `${process.env.REACT_APP_API_URL}/users?size=1000&companyId=${getCompanyId()}`;

        fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(res => res.json())
            .then(
                response => {

                    if (response._embedded.users.length !== 0) {

                        const index = response._embedded.users.map(function(e) {return e.id; }).indexOf(id);
                        const page = index === -1 ? 1 : Math.floor(index / this.state.itemsPerPage) + 1;

                        !(index === -1)  ?
                            this.setState({
                                chosenUserId: id,
                                chosenUser: response._embedded.users.find(x => x.id === id),
                                chosenUserName: response._embedded.users.find(x => x.id === id).name,
                                goToPage: page,
                                isLoaded: true,
                                items: response._embedded.users,
                                error: false
                            })
                            :
                            this.setState({
                                chosenUserId: response._embedded.users[0].id,
                                chosenUser: response._embedded.users[0],
                                chosenUserName: response._embedded.users[0].name,
                                goToPage: page,
                                isLoaded: true,
                                items: response._embedded.users,
                                error: false
                            })
                    }
                }
            )
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                    items: [],
                    error
                });
            })
    }

    handleOpenAddModal() {
        this.setState({
            showAddModal: true
        });
    }

    handleCloseAddModal() {
        this.setState({
            showAddModal: false
        });
    }

    async createUser(id, email, password, firstName, lastName, initials, company, role) {

        this.setState({
            isLoaded: false
        });

        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('mapperToken')
            },
            body: JSON.stringify({
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                initials: initials,
                companyId: company.value,
                // company: 'companies/' + company.value,
                role: role.value.toUpperCase()
            })
        });

        const json = await response.json();

        this.handleCloseAddModal();
        this.fetchUsers(json.id);
    };

    handleOpenDeleteModal() {

        this.setState({
            showDeleteModal: true
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    deleteUser() {

        const deleteItemId = this.state.chosenUserId;

        fetch(`${process.env.REACT_APP_API_URL}/users/${deleteItemId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(this.fetchUsers)
            .then(this.handleCloseDeleteModal)
    }

    handleListItemClick(e) {
        this.setState({
            chosenUserId: Number(e.target.parentElement.id),
            chosenUserName: this.state.items.find(x => x.id === this.state.chosenUserId).name
        });
    }

    render() {

        const { error, isLoaded, items } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <Spinner/>;
        } else {
            return (
                <div className='page'>
                    <div className='pageHeader'>
                        <div>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Users')}
                                    </span>
                                }
                            </Translation>
                        </div>
                        <div className='buttonsWrapper'>
                            <i className='material-icons createIcon'
                               onClick={this.handleOpenAddModal}
                            >
                                add
                            </i>
                        </div>
                    </div>
                    <div className='pageContent'>
                        <div className='pageContentColumn1-2'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <List
                                        items={items}
                                        filter={false}
                                        columnsTitles={[t('Email'), t('FullName'), t('Initials'), t('Role')]}
                                        columnsSources={['email', 'fullName', 'initials', 'role']}
                                        roleColumns={[4]}
                                        edit={false}
                                        download={false}
                                        delete={false}
                                        currentPage={this.state.goToPage}
                                        itemsPerPage={this.state.itemsPerPage}
                                        searchValue={this.props.searchValue}
                                        handleListItemClick={this.handleListItemClick}
                                        chosenId={this.state.chosenUserId}
                                    />
                                }
                            </Translation>
                        </div>
                        <div className='pageContentColumn2-2'>
                            {this.state.chosenUserId ?
                                <UserInfo
                                    item={items.find(x => x.id === this.state.chosenUserId)}
                                    handleOpenDeleteModal={this.handleOpenDeleteModal}
                                    fetchUsers={this.fetchUsers}
                                    chosenUserId={this.state.chosenUserId}
                                />
                                :
                                null
                            }
                        </div>
                    </div>

                    {this.state.showAddModal
                        ?
                        <UserAddModal
                            modalTitle='AddNewUser'
                            closeMethod={this.handleCloseAddModal}
                            saveMethod={this.createUser}
                        />
                        :
                        null
                    }

                    {this.state.showDeleteModal
                        ?
                        <DeleteModal
                            itemName={items.find(x => x.id === this.state.chosenUserId).fullName}
                            closeMethod={this.handleCloseDeleteModal}
                            deleteMethod={this.deleteUser}
                        />
                        :
                        null
                    }
                </div>
            );
        }
    }
}

export default Users;
