import React from 'react';
import './FloorPlanEditModal.css';
import {Translation} from "react-i18next";

function ValidationMessage(props) {
    if (!props.valid && props.message) {
        return(
            <Translation>
                {(t, {i18n}) => <div className='error-msg'>{t(`${props.message}`)}</div>}
            </Translation>
        )
    }
    return null;
}

class FloorPlanEditModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            nameInput: this.props.name, nameValid: !!this.props.name,
            errorMsg: {}
        };
        this.handleEditFloorPlanClick = this.handleEditFloorPlanClick.bind(this);
    }

    //Validation functions:
    validateName = () => {
        const {nameInput} = this.state;
        let errorMsg = {...this.state.errorMsg};
        let nameValid = true;

        if(nameInput === undefined || nameInput === '') {
            nameValid = false;
            errorMsg.nameInput = 'PleaseTypeInTheName'
        } else if(nameInput.length < 3) {
            nameValid = false;
            errorMsg.nameInput = 'NameNeedsToContainAtLeast3Characters'
        } else if(nameInput.length > 30) {
            nameValid = false;
            errorMsg.nameInput = 'NameCannotBeLongerThan30Characters'
        }

        this.setState({nameValid, errorMsg})
    };

    handleEditFloorPlanClick = () => {
        if(this.state.nameValid !== true) {
            this.validateName()
        } else {
            this.props.saveMethod(this.props.id, this.state.nameInput, this.props.date, this.props.url, this.props.floorId)
        }
    };

    render() {
        return (
            <div className='editFloorPlanModalBackground'>
                <div className='editFloorPlanModalWindow'>
                    <div className='editFloorPlanModalTitle'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t(this.props.modalTitle)}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className='editFloorPlanModalForm'>
                        <label className='editFloorPlanModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Name')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.nameInput} />
                        <input className='editFloorPlanModalInput'
                               defaultValue={this.props.name}
                               onInput={e => this.setState({nameInput: e.target.value}, this.validateName)}
                               type="text"
                        />
                        <div className='editFloorPlanModalButtons'>
                            <button
                                className='editFloorPlanModalButton editFloorPlanModalButton--cancel'
                                onClick={this.props.closeMethod}
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Cancel').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                            <button
                                className='editFloorPlanModalButton editFloorPlanModalButton--save'
                                onClick={this.handleEditFloorPlanClick}
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Save').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FloorPlanEditModal;
