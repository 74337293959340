import React from 'react';
import './LoginPage.css';
import {Translation} from "react-i18next";
import { NavLink } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

class LoginPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            usernameInput: '',
            passwordInput: ''
        };

        this.loginMethod = this.loginMethod.bind(this);
        this.loginOnEnter = this.loginOnEnter.bind(this);
    }

    showLoginMessage() {
        document.getElementById('loginErrorMessage').classList.remove('loginFailed');
        document.getElementById('loginExternalMessage').classList.remove('loginExternal');
        document.getElementById('loginSuccessMessage').classList.add('loginSuccess');
    }

    showExternalMessage() {
        document.getElementById('loginErrorMessage').classList.remove('loginFailed');
        document.getElementById('loginExternalMessage').classList.add('loginExternal');
    }

    removeMessages() {
        document.getElementById('loginErrorMessage').classList.remove('loginFailed');
        document.getElementById('loginExternalMessage').classList.remove('loginExternal');
        document.getElementById('loginSuccessMessage').classList.remove('loginSuccess');
    }

    loginMethod() {

        fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: 'POST',
            body: JSON.stringify({
                username: this.state.usernameInput,
                password: this.state.passwordInput
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.status === 200 && jwtDecode(res.headers.get('Authorization')).role !== 'EXTERNAL') {
                    this.setState({
                        usernameInput: '',
                        passwordInput: ''
                    }, () =>
                        this.showLoginMessage()
                    );
                    return res.headers.get('Authorization')

                } else if (res.status === 200 && jwtDecode(res.headers.get('Authorization')).role === 'EXTERNAL') {
                    this.showExternalMessage()
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .then((token) => {
                if(token) {
                    sessionStorage.setItem('mapperToken', token);
                    return token;
                }
            })
            .then((token) => setTimeout(() => {this.props.history.push('/buildingsDetails')}, 1500))
            .catch(err => {
                document.getElementById('loginSuccessMessage').classList.remove('loginSuccess');
                document.getElementById('loginExternalMessage').classList.remove('loginExternal');
                document.getElementById('loginErrorMessage').classList.add('loginFailed');
            });
    }

    loginOnEnter(e) {

        if(e.keyCode === 13) {
            this.loginMethod()
        }
    }

    render() {

        return (
            <div className='loginPage'>
                <div
                    className='loginWindow'
                    onKeyDown={this.loginOnEnter}
                >
                    <div className='loginIconHolder'>
                        <i className='material-icons loginIcon'>
                            person
                        </i>
                    </div>
                    <div id='loginErrorMessage' className='loginErrorMessage'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('LoginFailed')}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div id='loginSuccessMessage' className='loginSuccessMessage'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('LoggingIn')}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div id='loginExternalMessage' className='loginExternalMessage'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('You are an authenticated user, but unable to log in due to not being an admin user')}
                                </span>
                            }
                        </Translation>
                    </div>
                    <label className='loginWindowInputLabel'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('Username')}
                                </span>
                            }
                        </Translation>
                    </label>
                    <input className='loginWindowInput'
                           onInput={e => this.setState({usernameInput: e.target.value}, this.removeMessages)}
                           type='text'
                    />
                    <label className='loginWindowInputLabel'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t('Password')}
                                </span>
                            }
                        </Translation>
                    </label>
                    <input className='loginWindowInput'
                           onInput={e => this.setState({passwordInput: e.target.value}, this.removeMessages)}
                           type='password'
                    />
                    <div className='loginButtonHolder'>
                        <button
                            className='loginButton'
                            onClick={this.loginMethod}
                        >
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('LogIn').toUpperCase()}
                                    </span>
                                }
                            </Translation>
                        </button>
                        <div className="resetPassword">
                            <NavLink
                                className='registerText'
                                to='/request_reset'
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                        {t('Forgot your password? Click here to request password reset').toUpperCase()}
                                    </span>
                                    }
                                </Translation>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage;
