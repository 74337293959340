import i18n from 'i18next';
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import translationFR from './locales/fr/translation.json';
import translationNL from './locales/nl/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    },
    fr: {
        translation: translationFR
    },
    nl: {
        translation: translationNL
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
