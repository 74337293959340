import React from 'react';
import './UserAddModal.css';
import Select from 'react-select';
import {getCompanyId, isSuperAdmin} from "../../../helpers/helpers";
import {Translation} from "react-i18next";

function ValidationMessage(props) {
    if (!props.valid && props.message) {
        return(
            <Translation>
                {(t, {i18n}) => <div className='error-msg'>{t(`${props.message}`)}</div>}
            </Translation>
        )
    }
    return null;
}

class UserAddModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            emailInput: this.props.email, emailValid: !!this.props.email,
            passwordInput: this.props.password, passwordValid: false,
            firstNameInput: this.props.firstName, firstNameValid: !!this.props.firstName,
            lastNameInput: this.props.lastName, lastNameValid: !!this.props.lastName,
            initialsInput: this.props.initials,
            companyInput: null, companyValid: !!this.props.company,
            roleInput: this.props.role ?  {value: this.props.role, label: this.props.role.replace(/_/g, ' ').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})} : null, roleValid: !!this.props.role,
            companies: [],
            errorMsg: {}
        };

        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.handleAddUserClick = this.handleAddUserClick.bind(this);
    }

    componentDidMount() {
        this.fetchCompanies();
    }

    fetchCompanies() {

        this.setState({
            isLoaded: false
        });

        const apiUrl = isSuperAdmin() ? `${process.env.REACT_APP_API_URL}/companies?size=1000` : `${process.env.REACT_APP_API_URL}/companies?size=1000&id=${getCompanyId()}`;

        fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(res => res.json())
            .then(
                response => {
                    if (response._embedded.companies.length !== 0) {
                        this.setState({
                            companies: response._embedded.companies,
                            isLoaded: true,
                            error: false,
                            companyInput: this.props.company ? {value: this.props.company, label: response._embedded.companies.find(x => x.id === Number(this.props.company)).name} : null
                        })
                    }
                }
            )
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                    items: [],
                    error
                });
            })
    }

    handleChangeCompany = companyInput => {
        this.setState({
            companyInput
        }, this.validateCompany);
    };

    handleChangeRole = roleInput => {
        this.setState({
            roleInput
        }, this.validateRole);
    };

    //Validation Functions:
    validateEmail = () => {
        const {emailInput} = this.state;
        let emailValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(emailInput === '' || emailInput === undefined) {
            emailValid = false;
            errorMsg.emailInput = 'PleaseTypeInAnEmail'
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput)){
            emailValid = false;
            errorMsg.emailInput = 'InvalidEmailFormat'
        }
        this.setState({emailValid, errorMsg})
    };

    validatePassword = () => {
        const {passwordInput} = this.state;
        let passwordValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(passwordInput === '' || passwordInput === undefined) {
            passwordValid = false;
            errorMsg.passwordInput = 'PleaseTypeInThePassword'
        } else if(passwordInput.length < 8) {
            passwordValid = false;
            errorMsg.passwordInput = 'PasswordNeedsToContainAtLeast8Characters'
        } else if (!/\d/.test(passwordInput)){
            passwordValid = false;
            errorMsg.passwordInput = 'PasswordMustContainADigit';
        } else if (!/[!@#$%^&*]/.test(passwordInput)){
            passwordValid = false;
            errorMsg.passwordInput = 'PasswordMustContainSpecialCharacter!@#$%^&*';
        }
        this.setState({passwordValid, errorMsg})
    };
    
    validateFirstName = () => {
        const {firstNameInput} = this.state;
        let firstNameValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(firstNameInput === '' || firstNameInput === undefined) {
            firstNameValid = false;
            errorMsg.firstNameInput = 'PleaseTypeInTheFirstName'
        } else if(firstNameInput.length < 3) {
            firstNameValid = false;
            errorMsg.firstNameInput = 'NameNeedsToContainAtLeast3Characters'
        } else if(firstNameInput.length > 30) {
            firstNameValid = false;
            errorMsg.firstNameInput = 'NameCannotBeLongerThan30Characters'
        }
        this.setState({firstNameValid, errorMsg})
    };

    validateLastName = () => {
        const {lastNameInput} = this.state;
        let lastNameValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(lastNameInput === '' || lastNameInput === undefined) {
            lastNameValid = false;
            errorMsg.lastNameInput = 'PleaseTypeInTheLastName'
        } else if(lastNameInput.length < 3) {
            lastNameValid = false;
            errorMsg.lastNameInput = 'NameNeedsToContainAtLeast3Characters'
        } else if(lastNameInput.length > 30) {
            lastNameValid = false;
            errorMsg.lastNameInput = 'NameCannotBeLongerThan30Characters'
        }
        this.setState({lastNameValid, errorMsg})
    };

    validateCompany = () => {
        const {companyInput} = this.state;
        let companyValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(companyInput === null || companyInput === undefined) {
            companyValid = false;
            errorMsg.companyInput = 'PleaseSelectCompany'
        }
        this.setState({companyValid, errorMsg})
    };

    validateRole = () => {
        const {roleInput} = this.state;
        let roleValid = true;
        let errorMsg = {...this.state.errorMsg};

        if(roleInput === null || roleInput === undefined) {
            roleValid = false;
            errorMsg.roleInput = 'PleaseSelectRole'
        }
        this.setState({roleValid, errorMsg})
    };

    handleAddUserClick = () => {
        const {emailValid, firstNameValid, lastNameValid, passwordValid, roleValid, companyValid} = this.state;
        const {firstNameInput, lastNameInput, emailInput, passwordInput, roleInput, companyInput, initialsInput} = this.state;

        if(emailValid !== true) {
            this.validateEmail()
        } else if(passwordValid !== true) {
            this.validatePassword()
        } else if(firstNameValid !== true) {
            this.validateFirstName()
        } else if(lastNameValid !== true) {
            this.validateLastName()
        } else if(companyValid !== true) {
            this.validateCompany()
        } else if(roleValid !== true) {
            this.validateRole()
        } else {
            this.props.saveMethod(this.props.id, emailInput, passwordInput, firstNameInput, lastNameInput, initialsInput, companyInput, roleInput)
        }
    };

    render() {

        const selectStyles = {
            container: (base, state) => ({
                ...base,
                fontSize: '16px',
                fontWeight: '300',
                width: '290px',
                height: '40px',
                marginBottom: '12px'
            }),
            control: (base, state) => ({
                ...base,
                boxShadow: state.isFocused ? 0 : 0,
                borderColor: state.isFocused
                    ? '#CCCCCC'
                    : base.borderColor,
                '&:hover': {
                    borderColor: state.isFocused
                        ? '#CCCCCC'
                        : base.borderColor,
                    cursor: 'pointer'
                }

            }),
            singleValue: (base, state) => ({
                ...base,
                color: '#666666'
            }),
            option: (base, state) => ({
                ...base,
                fontSize: '16px',
                color: state.isFocused ? '#0047AB' : '#666666',
                fontWeight: state.isFocused ? '400' : '300',
                background: state.isFocused ? '#fff' : '#fff',
                backgroundColor: state.isSelected ? '#fff' : '#fff',
                '&:hover': {
                    backgroundColor: state.isSelected ? '#fff' : '#fff'
                },
                borderBottom: '1px solid #D7D7D7',
                '&:last-child': {
                    borderBottom: '0'
                },
                cursor: 'pointer'
            })
        };

        const optionsCompanies = this.state.companies.map(function (company) {
            return { value: company.id, label: company.name };
        });

        const optionsRoles = isSuperAdmin() ?

            process.env.REACT_APP_ROLES.split(',').map(function (role) {
                return { value: role, label: role.replace(/_/g, ' ').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) };
            })
            :
            process.env.REACT_APP_ROLES_NO_SUPER_ADMIN.split(',').map(function (role) {
                return { value: role, label: role.replace(/_/g, ' ').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) };
            })
        ;

        return (
            <div className='addUserModalBackground'>
                <div className='addUserModalWindow'>
                    <div className='addUserModalTitle'>
                        <Translation>
                            {(t, {i18n}) =>
                                <span>
                                    {t(this.props.modalTitle)}
                                </span>
                            }
                        </Translation>
                    </div>
                    <div className='addUserModalForm'>
                        <label className='addUserModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Email')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.emailValid} message={this.state.errorMsg.emailInput} />
                        <input className='addUserModalInput'
                               defaultValue={this.props.email}
                               onInput={e => this.setState({emailInput: e.target.value}, this.validateEmail)}
                               type="email"
                        />
                        <label className='addUserModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Password')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.passwordValid} message={this.state.errorMsg.passwordInput} />
                        <input className='addUserModalInput'
                               defaultValue={this.props.password}
                               onInput={e => this.setState({passwordInput: e.target.value}, this.validatePassword)}
                               type="password"
                        />
                        <label className='addUserModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('FirstName')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.firstNameValid} message={this.state.errorMsg.firstNameInput} />
                        <input className='addUserModalInput'
                               defaultValue={this.props.firstName}
                               onInput={e => this.setState({firstNameInput: e.target.value}, this.validateFirstName)}
                               type="text"
                        />
                        <label className='addUserModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('LastName')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.lastNameValid} message={this.state.errorMsg.lastNameInput} />
                        <input className='addUserModalInput'
                               defaultValue={this.props.lastName}
                               onInput={e => this.setState({lastNameInput: e.target.value}, this.validateLastName)}
                               type="text"
                        />
                        <label className='addUserModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Initials')}
                                    </span>
                                }
                            </Translation>
                        </label>
                        <input className='addUserModalInput'
                               defaultValue={this.props.initials}
                               onInput={e => this.setState({initialsInput: e.target.value})}
                               type="text"
                        />
                        <label className='addUserModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Company')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.companyValid} message={this.state.errorMsg.companyInput} />
                        <Select
                            value={this.state.companyInput}
                            onChange={this.handleChangeCompany}
                            options={optionsCompanies}
                            isClearable
                            placeholder=''
                            styles={selectStyles}
                            menuPlacement='top'
                        />
                        <label className='addUserModalInputLabel'>
                            <Translation>
                                {(t, {i18n}) =>
                                    <span>
                                        {t('Role')}*
                                    </span>
                                }
                            </Translation>
                        </label>
                        <ValidationMessage valid={this.state.roleValid} message={this.state.errorMsg.roleInput} />
                        <Select
                            value={this.state.roleInput}
                            onChange={this.handleChangeRole}
                            options={optionsRoles}
                            isClearable
                            placeholder=''
                            styles={selectStyles}
                            menuPlacement='top'
                        />
                        <div className='addUserModalButtons'>
                            <button
                                className='addUserModalButton addUserModalButton--cancel'
                                onClick={this.props.closeMethod}
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Cancel').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                            <button
                                onClick={this.handleAddUserClick}
                                className='addUserModalButton addUserModalButton--save'
                            >
                                <Translation>
                                    {(t, {i18n}) =>
                                        <span>
                                            {t('Save').toUpperCase()}
                                        </span>
                                    }
                                </Translation>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserAddModal;
