import React from 'react';
import './FloorPlanInfo.css';

import UploadBar from '../common/UploadBar/UploadBar';
import DeleteModal from '../common/DeleteModal/DeleteModal';
import FloorPlanEditModal from './FloorPlanEditModal/FloorPlanEditModal';
import {Translation} from "react-i18next";

class FloorPlanInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: true,
            items: [],
            error: false,
            files: [],
            chosenFloorId: this.props.chosenFloorId,
            editedItemName: '',
            editedItemId: null,
            showEditModal: false,
            showDeleteModal: false
        };

        this.uploadFloorPlan = this.uploadFloorPlan.bind(this);
        this.fetchFloorPlan = this.fetchFloorPlan.bind(this);
        this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.deleteFloorPlan = this.deleteFloorPlan.bind(this);
        this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.editFloorPlan = this.editFloorPlan.bind(this);
    }

    componentDidMount() {
        this.setState({
            chosenFloorId: this.props.chosenFloorId
        }, () => this.fetchFloorPlan());
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.chosenFloorId!==this.props.chosenFloorId){
            this.fetchFloorPlan();
        }
    }

    fetchFloorPlan() {
        this.setState({
            isLoaded: false
        });

        fetch(`${process.env.REACT_APP_API_URL}/mapperFloors/${this.props.chosenFloorId}/floorPlans`, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('mapperToken')
            }
        })
            .then(res => res.json())
            .then(
                response => {
                    this.setState({
                        isLoaded: true,
                        items: response._embedded.floorPlans,
                        error: false
                    });
                }
            )
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                    items: [],
                    error
                });
            })
    }

    uploadFloorPlan(files) {

        const formData = new FormData();
        formData.append('file', files[0], files[0].name);

        fetch(`${process.env.REACT_APP_API_URL}/floors/${this.props.chosenFloorId}/floorPlans/upload`, {
            method: 'PUT',
            headers: {
            'Authorization': sessionStorage.getItem('mapperToken')
        },
            body: formData,
        })
            .then(this.props.fetchFloors)
    }

    handleOpenEditModal(e) {
        const editedItemId = e.target.parentElement.parentElement.id;
        const index = this.state.items.findIndex(x => x.id === Number(editedItemId));

        const editedItemName = this.state.items[index].name;
        const editedItemDate = this.state.items[index].createdAt;
        const editedItemUrl = this.state.items[index].fileUrl;
        const editedItemFloorId = this.state.items[index].floorId;

        this.setState({
            editedItemId: editedItemId,
            editedItemName: editedItemName,
            editedItemDate: editedItemDate,
            editedItemUrl: editedItemUrl,
            editedItemFloorId: editedItemFloorId,
            showEditModal: true
        });
    }

    handleCloseEditModal() {
        this.setState({
            showEditModal: false
        });
    }

    editFloorPlan(id, name, date, url, floorId) {

        this.setState({
            isLoaded: false
        });

        fetch(`${process.env.REACT_APP_API_URL}/floorPlans/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                id: id,
                name: name,
                createdAt: date,
                fileUrl: url,
                floorId: floorId
            })
        })
            .then(res => res.json())
            .then(this.handleCloseEditModal)
            .then(this.fetchFloorPlan)
    }

    handleOpenDeleteModal(e) {
        const editedItemName = e.target.parentElement.parentElement.children[0].innerText;
        const editedItemId = e.target.parentElement.parentElement.id;

        this.setState({
            editedItemName: editedItemName,
            editedItemId: editedItemId,
            showDeleteModal: true
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    deleteFloorPlan() {

        const deleteItemId = this.state.editedItemId;

        const apiUrl = `${process.env.REACT_APP_API_URL}/floorPlans/` + deleteItemId;

        fetch(apiUrl, {
            method: 'DELETE'
        })
            .then(this.fetchFloorPlan)
            .then(this.handleCloseDeleteModal)
            .then(this.props.fetchFloors)
    }

    render() {
        const { projects, selectedProject } = this.props;
        const { isLoaded, items } = this.state;

        if (!isLoaded) {
            return null;
        } else {
            return (
                <div>
                    <Translation>
                        {(t, {i18n}) =>
                            <UploadBar
                                uploadBarText={t('DropFileOrClick')}
                                sendMethod={this.uploadFloorPlan}
                            />
                        }
                    </Translation>
                    {items.length
                        ?
                            <div className='floorPlanInfo'>
                                <div
                                    className='floorPlanInfoTitleBar'
                                    id={items[0].id}
                                >
                                    <div className='floorPlanInfoTitle'>
                                        {items[0].name}
                                    </div>
                                    <div className='floorPlanInfoActions'>
                                        <i className='material-icons actionIcon'
                                           onClick={this.handleOpenEditModal}
                                        >
                                            edit
                                        </i>
                                        <i className='material-icons actionIcon actionIcon--delete'
                                           onClick={this.handleOpenDeleteModal}
                                        >
                                            delete
                                        </i>
                                    </div>
                                </div>
                                <div className='floorPlanInfoContent'>
                                    <div className='floorPlanInfoSectionLeft'>
                                        <div className='floorPlanInfoLabel'>
                                            {projects[projects.findIndex(x => x.id === selectedProject)].name}
                                        </div>
                                        <div className='floorPlanInfoText'>
                                            <Translation>
                                                {(t, {i18n}) =>
                                                    <span>
                                                        {t('Created')}
                                                    </span>
                                                }
                                            </Translation>
                                            : {items[0].createdAt.split('T').shift()}
                                        </div>
                                    </div>
                                    {/*<div className='floorPlanInfoSectionRight'>*/}
                                    {/*    <div className='floorPlanInfoText'>*/}
                                    {/*        Status*/}
                                    {/*    </div>*/}
                                    {/*    <div className='floorPlanInfoStatus'>*/}
                                    {/*        {*/}
                                    {/*            <i className='material-icons statusIcon--success'>*/}
                                    {/*                done*/}
                                    {/*            </i>*/}
                                    {/*        }*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        :
                            null
                    }

                    {this.state.showEditModal
                        ?
                        <FloorPlanEditModal
                            modalTitle='EditFloorPlan'
                            id={this.state.editedItemId}
                            name={this.state.editedItemName}
                            date={this.state.editedItemDate}
                            url={this.state.editedItemUrl}
                            floorId={this.state.editedItemFloorId}
                            closeMethod={this.handleCloseEditModal}
                            saveMethod={this.editFloorPlan}
                        />
                        :
                        null
                    }

                    {this.state.showDeleteModal
                        ?
                        <DeleteModal
                            itemName={this.state.editedItemName}
                            closeMethod={this.handleCloseDeleteModal}
                            deleteMethod={this.deleteFloorPlan}
                        />
                        :
                        null
                    }

                </div>
            );
        }
    }
}

export default FloorPlanInfo;
