import jwtDecode from 'jwt-decode';

export function getTokenExp() {
    return jwtDecode(sessionStorage.getItem('mapperToken')).exp
}

export function getCompany() {
    return jwtDecode(sessionStorage.getItem('mapperToken')).company
}

export function getCompanyId() {
    return jwtDecode(sessionStorage.getItem('mapperToken')).companyId
}

export function isSuperAdmin() {
    return jwtDecode(sessionStorage.getItem('mapperToken')).role === 'SUPER_ADMIN'
}

export function isAdmin() {
    return jwtDecode(sessionStorage.getItem('mapperToken')).role === 'ADMIN'
}

export function isDesigner() {
    return jwtDecode(sessionStorage.getItem('mapperToken')).role === 'DESIGNER'
}
