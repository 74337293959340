import React from 'react';
import './BuildingIfcs.css';

class BuildingIfcsListItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isChecked: false,
            ifcId: this.props.id
        };

        this.includeIfc = this.includeIfc.bind(this);
    }

    includeIfc(e) {
        this.setState({
            isChecked: !this.state.isChecked
        }, () =>
            this.props.getIfcInputs(this.props.ifc.spaces, this.state.isChecked, this.state.ifcId)
        );

        e.target.parentElement.parentElement.parentElement.classList.toggle('checked')
    }

    render() {

        const {id, ifc} = this.props;

        return (
            <tr className='buildingIfcsTableRow'>
                <td
                    className='buildingIfcsTableField buildingIfcsTableField--column1'
                >
                    {this.props.ifc.spaces && this.props.spacesIfcInputToRender && this.props.spacesIfcInputToRender !== this.props.ifc.id
                    ?
                        <div onClick={this.props.showSpacesIfcAlert}>
                            <input
                                className='ifcCheckbox'
                                id={'ifcCheckbox' + id}
                                type='checkbox'
                                disabled
                            />
                            <label htmlFor={'ifcCheckbox' + id}>
                                {ifc.name}
                            </label>
                        </div>
                    :
                        <div>
                            <input
                                id={'ifcCheckbox' + id}
                                type='checkbox'
                                onChange={this.includeIfc}
                            />
                            <label htmlFor={'ifcCheckbox' + id}>
                                {ifc.name}
                            </label>
                        </div>
                    }
                </td>
                <td
                    className='buildingIfcsTableField buildingIfcsTableField--column2'
                >
                    {ifc.prefix}
                </td>
                <td
                    className='buildingIfcsTableField buildingIfcsTableField--column3'
                >
                    {ifc.spaces
                        ? <i className="material-icons">done</i>
                        : ''
                    }
                </td>
                <td className='buildingIfcsTableField buildingIfcsTableField--actions'>
                    <div className='actionIcons'>
                        <i
                            className='material-icons actionIcon actionIcon--buildingIfcs'
                            onClick={() => this.props.editMethod(ifc)}
                        >
                            edit
                        </i>
                    </div>
                </td>
            </tr>
        );
    }
}

export default BuildingIfcsListItem;
